import ModulesApi from '@/api/core/modules';
import il8n from '@/translations';
import menuApi from "../../api/core/menu";

const initialState = () => ({
    modulesList: null,
    noMultiCenterPageList: [ 'groups', 'spaces' ]
});
export const common = {
    state: initialState(),
    mutations: {
        setModulesList(state, option){
            state.modulesList = option
        },
        resetCenterInfo(state) {
            const newState = initialState();
            Object.keys(newState).forEach(key => {
                state[key] = newState[key]
            });
        },
    },
    actions: {
        async getModulesList(context, params){
            if (context.state.modulesList !== null && context.state.modulesList !== "pending"){
                return context.state.modulesList;
            }
            else if (context.state.modulesList !== "pending"){
                context.commit('setModulesList', "pending");
                await ModulesApi.getModules().then((response) => {
                    let modulesTrans = [];
                    for (var i = 0; i < response.data.modules.length; i++) {
                        modulesTrans[i] = response.data.modules[i];
                        modulesTrans[i].keyName = modulesTrans[i].name;
                        modulesTrans[i].name = il8n.tc("module." + modulesTrans[i].name);
                    }
                    context.commit("setModulesList", modulesTrans);
                });
            }
        },
    },
    getters: {
        getModulesList: state => {
            return state.modulesList
        },
        getNoMultiCenterPageList: state => {
            return state.noMultiCenterPageList
        },
        hasModule:  (state) => (moduleName) => {
            if(Array.isArray(state.modulesList)){
                let module =  state.modulesList.filter(m => {   if (m.name == moduleName){  return m;  }  });
                if (module && module.length > 0 && module[0].moduleCenter.length > 0) {  return true; }
                else{  return false;  }
            }
            else{
                return false;
            }
        }
    }
};
