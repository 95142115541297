<template>
<div>
        <div class="sub-button">
            <p>
                <a class="privacity-link"  @click="openPrivacity">{{$t('privacityRules') }}</a> |
                <a class="recover-password" href="#">{{ $t('needsHelp')}}</a>
            </p>
          <Modal_privacity :visible="visibility" @close="visibility=false" />
        </div>

</div>
</template>
<script>

    import Modal_privacity from "./ModalPrivacity";
    export default {
        name: "recoverHelp",
        components: {Modal_privacity},
        data(){
            return{
                visibility:false
            }
        },
        methods:{
            openPrivacity(){
                // console.log('open');
                this.visibility=true;
            }
        }

    }
</script>
<style>
    .sub-button{
        padding-bottom: 50px;
        padding-top: 19px;

    }
    .sub-button p{
        margin-bottom: 0px;
    }

    @media (max-width: 420px){
        .sub-button p{
        font-size: 0.7rem;
    }
    }
</style>
