import axios from 'axios';
import entityHelper from "@/api/entityHelper";

export default {

    getMessages(folder, filters, page, pageSize, orderField, orderMode) {
        // console.log(filters, page, pageSize, orderField, orderMode);
        return entityHelper.findEntity("/messaging/find/" + folder, filters, page, pageSize, orderField, orderMode);
    },

    getMessage(id, folder, year) {
        let params = {};

        if (year) {
            params['year'] = year;
        }

        return axios.get("/messaging/read/" + folder + '/' + id, { params });
    },
    deleteMessage(message) {
        return axios.delete('/messaging/delete/' + message.id);
    },
    deleteMessages(messageList, folder) {
        let params = {messagesIds: messageList, folder: folder}
        return axios.delete('/messaging/delete',{params});
    },
    markUnread(id) {
        return axios.post("/messaging/unread/" + id);
    },
    getUnreadMessages(cancelToken = null) {
        return axios.get("/messaging/unread_count", {
            params: {},
            cancelToken: cancelToken
        });
    },
    downloadAttachment(message, messageAttachmentId, year = null) {
        let requestParams = {};

        if (year != null) {
            requestParams['year'] = year;
        }

        return axios.get("/messaging/attachment/" + messageAttachmentId, {
            responseType: 'blob',
            params: requestParams,
            validateStatus: function (status) {
                return true;
            }
        });
    },
    uploadMessageFiles(files){
        let formData = new FormData();
        if (files.length > 0) {
            files.forEach(f => {
                formData.append("files[]", f);
            });
        }
        return axios.post('/messaging/uploadMessageFiles', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    sendMessage(message, temporaryFiles, to, cc, bcc, attachmentsOfOtherMessage, dateSend, hourSend, channels) {
        let params = {"message": message, "to": to, "cc": cc, "bcc": bcc, "temporaryFiles": temporaryFiles, attachmentsOfOtherMessage: attachmentsOfOtherMessage};
        if(dateSend!==''){ params['date'] = dateSend }
        if(hourSend!==''){ params['hour'] = hourSend }
        if(channels.length>0){ params["channels"] = channels } else { params["channels"] = ["DIRECT"]; }
        return axios.post('/messaging/sendMessage', {params});
    },
    removeTemporaryFiles(temporaryFiles) {
        let params = {"temporaryFiles": temporaryFiles};
        return axios.delete('/messaging/removeMessageTemporaryFile', {params});
    },
    toggleStatus(messagesIds, status){
        let params = {messagesIds: messagesIds, status: status};
        return axios.post("/messaging/toggleStatus/", {params});
    },
    /*getTokenCentrifugo() {
        return axios.get("/centrifugo/token");
    },*/


}
