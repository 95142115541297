export default {
    "studentCommentSaved": "El comentari de l'alumne s'ha desat correctament",
    "confirmDeleteComment": "Segur que vols eliminar el comentari '{commentText}'? ",
    "studentCommentDeleted": "El comentari de l'alumne s'ha eliminat correctament",
    "homework": "Deures",
    "classTime": "Classes (horari)",
    "classNoTime": "Classes",
    "plannedAt": "Data planificació",
    "hours": "Hores",
    "plannedHours": "Hores planificades",
    "executedAt": "Data Execució",
    "executedHours": "Hores executades",
    "noClassContents": "Aquesta setmana no té cap sessió, revisa la setmana següent o l'anterior.",
    "contentText": "Text",
    "evaluation": "Avaluació",
    "planned": "Planificat",
    "notPlanned": "No Planificat",
    "executed": "Realitzat",
    "notExecuted": "No realitzat",
    "done": "Fet",
    "addClassContent": "Afegir nou contingut",
    "newClassContent": "Nova entrada - {elementType}",
    "editClassContent": "Editar entrada - {elementType}",
    "showClassContent": "Veure entrada - {elementType}",
    "confirmDeleteEvent": "Segur que voleu eliminar l'event seleccionat?",
    "confirmDeleteClassContent": "Voleu eliminar el contingut seleccionat?",
    "evaluationCriteria": "Criteri d'avaluació|Criteri d'avaluacions",
    "textComment": "Text del comentari",
    "lessons": "Temaris",
    "sessionNoContent": "No s'ha definit què impartiràs en aquesta sessió",
    "sessionNoContentTEMARY": "No s'han definit quins continguts impartiràs en aquesta classe",
    "sessionNoContentOBJECTIVES": "No s'han definit quins objectius treballaràs en aquesta classe",
    "sessionNoContentACTIVITY": "No s'han definit quines activitats fareu en aquesta classe",
    "sessionNoContentDOCUMENTS": "No hi ha arxius adjunts en aquesta classe",
    "sessionNoContentCOMPETENCES": "Quines competències treballaràs en aquesta classe?",
    "sessionNoContentDESCRIPTION": "No hi ha cap descripció per aquesta sessió",
    "selectAddContent": "Afegeix el que impartiràs en aquesta sessió",
    "selectAddContentTEMARY": "Continguts de '{classe}'",
    "selectAddContentOBJECTIVES": "Objectius de '{classe}'",
    "selectAddContentACTIVITY": "Activitats de '{classe}'",
    "selectAddContentDOCUMENTS": "Arxius de '{classe}'",
    "selectAddContentCOMPETENCES": "Competències de '{classe}'",
    "selectAddContentDESCRIPTION": "Descripcions de '{classe}'",
    "objectives": "Objectius",
    "activities": "Activitats",
    "newTask": "Nova tasca",
    "tasksDone": "Tasques realitzades",
    "progress": "Progressió",
    "addLesson": "Nou temari",
    "addTask": "Afegir tasca",
    "noContentAvailable": "No hi ha contingut disponible.",
    "sessions": "Sessions",
    "sessionsTEMARY": "Temari / Continguts",
    "sessionsOBJECTIVES": "Objectius",
    "sessionsACTIVITY": "Activitats",
    "sessionsDESCRIPTION": "Descripcions",
    "noSubProgramContent": "No té subtemes definits",
    "back_to_class": "Tornar al seguiment",
    "classContentDeleted": "El contingut de classe s'ha esborrat correctament.",
    "confirmDeleteClasse": "Segur que vols esborrar la classe d'aquest dia? Si hi ha continguts, mou-los prèviament!",
    "selectClass": "Selecciona una classe",
    "addSession": "Afegir una sessió nova",
    "TEMARY": "Temari",
    "OBJECTIVES": "Objectius",
    "ACTIVITY": "Activitats",
    "DOCUMENTS": "Documents",
    "COMPETENCES": "Competències",
    "DESCRIPTION": "Descripcions",
    "contentType": {
        "TEMARY": "Temari|Temaris",
        "OBJECTIVES": "Objetiu|Objectius",
        "ACTIVITY": "Activitat|Activitats",
        "DOCUMENTS": "Document|Documents",
        "DOCUMENT": "Document|Documents",
        "COMPETENCES": "Competència|Competències",
        "COMPETENCE": "Competència|Competències",
        "DESCRIPTION": "Descripció|Descripcions",
        "COMMENT": "Comentari|Comentaris",
        "REFLECTION": "Reflexió|Reflexions",
    }
}
