export default {
    "contact.data": "Dades de contacte",
    "responsables": "Familiars",
    "person.data" : "Dades Personals",
    "person.basicData" : "Dades bàsiques",
    "person.lastName": "Cognom {num}",
    "person.dni":"DNI",
    "person.rol":"Permís",
    "person.gender": "Gènere",
    "person.address": "Direcció",
    "person.town": "Població",
    "person.zip": "Codi Postal",
    "person.state":"Província",
    "person.nationality":"Nacionalitat",
    "person.birthday": "Data de Naixament",
    "person.created": "Data d'alta",
    "person.SS": "Num. Seguretat Social",
    "person.contact": "Contacte",
    "contact.delete": "Segur que vols eliminar el contacte '{count}'?",
    "person.relationtype": "Tipus de relació",
    "person.relationtype.parent": "Pare",
    "person.relationtype.mother": "Mare",
    "person.relationtype.tutor": "Tutor/a legal",
    "person.relationtype.avi": "Avi",
    "person.relationtype.avia": "Àvia",
    "person.relationtype.cangur": "Cangur",
    "person.relationtype.altres": "Relació desconeguda",
    "parent.delete": "Segur que vols eliminar el familiar '{name}'?",
    "person.type.admin":"Administrador",
    "person.type.student":"Alumno",
    "person.type.teacher":"Docent",
    "person.type.parent":"Pare/Mare",
    "person.man":"Home",
    "person.woman":"Dona",
    "person.undefined":"Indefinit",
    "TEACHER": "Professorat",
    "PAS": "PAS",
    "PARENT": "Familiar",
    "ADMIN": "Administrador",
    "user.rol":"Rol",
    "person.related.not_exist": "La persona no té familiars (pare/mare/tutor legal...) relacionats.",
    "person.related.children.not_exist": "La persona no té fills relacionats.",
    "user.basicData" : "Dades d'usuari",
    "person.academicrecord": "Expedient acadèmic",
    "person.documents": "Documents",
    "person.user.not_exist": "La persona no té usuari i per tant no pot accedir a la intranet.",
    "enrolment": "Matrícula",
    "enrolment.info": "Dades de la matrícula",
    "enrolment.numCal": "Convocatòria",
    "enrolment.validateNote": "Nota convalidada",
    "enrolment.programs.select": "No està matriculat/da a cap matèria",
    "enrolment.state.ACTIVE": "Matriculat",
    "enrolment.state.NO_ENROLMENT":" No Matriculat",
    "enrolment.state.CANCELED": "Baixa",
    "person.isImportable": "S'actualitza de l'importador",
    "person.children": "Fills",
    "warning.deleterole": "Segur que vols eliminar el rol \"{role}\"?",
    "addsubrole": "Afegir subrol",
    "editrole": "Editar rol",
    "roleusers": "Usuaris d'aquest rol",
    "deleteuser.title": "Eliminar usuari",
    "deleteuser.body": "Segur que vols eliminar l'usuari {name} ?",
    "adduser.title": "Busca l'usuari que vols afegir al rol {role}",
    "person.status": "Estat persona",
    "person.additionalData": "Dades addicionals",
    "person.addUserAlert": "Per afegir usuari faci clic al botó ",
    "child.delete": "Segur que vols eliminar el fill '{name}'?",
    "person.children.not_exist": "La persona no té fills relacionats.",
    "sendUserPassReminder": "Enviar claus d'accés",
    "noPersonsInList": "El llistat d'usuaris està buit.",
    "titleSendUserReminder": "Enviar recordatori d'usuari",
    "confirmSendUserReminder": "A continuació s'enviarà un correu electrònic recordatori amb el nom usuari i un enllaç per recuperar la contrasenya, a tots els usuari del llistat.<br /><br />Està segur que vol enviar els correus electrònics recordatori?",
    "userReminderSent": "No s'ha enviat cap correu electrònic.|S'ha enviat 1 correu electrònic.|S'han enviat {totalSent} correus electrònics.",
    "personExtraField.filed.delete": "Està  apunt de borrar el camp personalitzat {filedName}. Si hi ha valors associats a aquest camp a les fitxes de les persones els perdrà. ",
}
