import axios from 'axios';

export default {

    get(url) {
        return axios.get(url)
    },
    findEntity(baseUrl, filters, page, pageSize, sortField, sortOrder, cancelToken) {
        // console.log(filters);
        // Construct the url based on incoming parameters
        let url = baseUrl
        let config = {}
        let params = {}

        if (page !== null) {
            params["page"] = page
        }
        if (pageSize !== null) {
            params["perPage"] = pageSize
        }
        if (typeof (sortField) !== "undefined") {
            params["sort"] = sortField;

            if (sortOrder !== null && sortOrder!='') {
                params["sort"] = params["sort"] + "|" + sortOrder
            } else if(params["sort"]!=null && params["sort"]!='') {
                params["sort"] = params["sort"] + "|" + "asc"
            }else{
                params["sort"] = ''
            }
        }

        if (typeof (filters["fields"]) !== 'undefined') {
            Object.keys(filters['fields']).forEach((f) => {
                // console.log("Processing field " + f);
                if (filters['fields'][f] !== null) {
                    params['filters[' + f + ']'] = filters['fields'][f]
                }
            })
        }
        if (typeof(filters["format"]) !== 'undefined' && filters["format"] !== null && filters["format"] !== "") {
            params["format"] = filters['format']
        }

        // params["filters"] = filters;
        if (typeof(filters["q"]) !== 'undefined' && filters["q"] !== null && filters["q"] !== "") {
            params["q"] = filters['q']
        }

        if ('withoutCompGrades' in filters && filters.withoutCompGrades != null) {
            params['withoutCompGrades'] = filters.withoutCompGrades;
        }

        //translation filters
        if(filters.translate !== undefined){
            params["translate_q"]=filters.translate.q;
        }

        //filters equals
        if (typeof (filters["equalsFields"]) !== 'undefined') {
            Object.keys(filters['equalsFields']).forEach((f) => {
                //console.log("Processing field " + f);
                if (filters['equalsFields'][f] !== null) {
                    params['equals_filter[' + f + ']'] = filters['equalsFields'][f]
                }
            })
        }
        if (typeof (filters["in_filters"]) !== 'undefined') {
            Object.keys(filters['in_filters']).forEach((f) => {
                // console.log("Processing field " + f);
                if (filters['in_filters'][f] !== null) {
                    params['in_filters[' + f + ']'] = filters['in_filters'][f]
                }
            })
        }
        if (typeof (filters["centers"]) !== 'undefined') {
            params['centers'] = filters['centers'];
        }

        if (typeof (filters["year"]) !== 'undefined') {
            params['year'] = filters['year'];
        }

        if (typeof (filters["concatFields"]) !== 'undefined') {
            Object.keys(filters['concatFields']).forEach((f) => {
                // console.log("Processing field " + f);
                if (filters['concatFields'][f] !== null) {
                    params['concatFilters[' + f + ']'] = filters['concatFields'][f]
                }
            })
        }

        if (typeof (filters["specialFields"]) !== 'undefined') {
            Object.keys(filters['specialFields']).forEach((f) => {
                // console.log("Processing field " + f);
                if (filters['specialFields'][f] !== null) {
                    params['specialFilters[' + f + ']'] = filters['specialFields'][f]
                }
            })
        }
        if (typeof (filters["biggerThanFields"]) !== 'undefined') {
            Object.keys(filters['biggerThanFields']).forEach((f) => {
                // console.log("Processing field " + f);
                if (filters['biggerThanFields'][f] !== null) {
                    params['biggerThanFilters[' + f + ']'] = filters['biggerThanFields'][f]
                }
            })
        }
        if (typeof (filters["smallerThanFields"]) !== 'undefined') {
            Object.keys(filters['smallerThanFields']).forEach((f) => {
                // console.log("Processing field " + f);
                if (filters['smallerThanFields'][f] !== null) {
                    params['smallerThanFilters[' + f + ']'] = filters['smallerThanFields'][f]
                }
            })
        }
        if (typeof filters === 'object' && 'year' in filters && filters.year && filters.year != null) {
            if (!filters.year.activeYear && url.indexOf("changeYear") === -1) {
                params['changeYear'] = filters.year.schemaName;
            }
        }

        if (typeof (filters["centerCode"]) !== 'undefined') {
            params['centerCode'] = filters['centerCode'];
        }
        if (typeof (filters["yearCode"]) !== 'undefined') {
            params['yearCode'] = filters['yearCode'];
        }

        config['params'] = params
        if (typeof(cancelToken) !== 'undefined') {
            config['cancelToken'] = cancelToken
        }

        return axios.get(url, config);

    },
    saveEntity(entity, url) {
        return axios.post(url, {
            entity
        }).then(response => {
            let success = response.data.status == "ok"
            let error = null;
            if (response.data.status == "ko") {
                error = response.data.error;
            }
            return {success: success, error: error, data: response.data}
        });
    },
    // Modifica un sol  camp de un registre
    updateEntity(data, url) {
        // let entity = { id: data.id}
        // entity[data.field] = data.value;
        return axios.post(url + '/' + data.id, data).then(response => {
            let success = response.data.status == "ok"
            let error = null;
            if (response.data.status == "ko") {
                error = response.data.error;
            }
            return {success: success, error: error, data: response.data}
        });
    },
    deleteEntity(entity, url) {
        //console.log(deleteEntity+" : url + "/" + entity.id);
        // console.log("deleteEntity", entity, url);
        let p = null;
        if (entity.constructor === Array) {
            p = axios.post(url, {elements: entity});
            return p;
        }
        p = axios.delete(url + "/" + entity.id, {
            entity
        });

        return p;
    }
}
