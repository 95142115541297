import axios from 'axios';

export default {
  requestToken(username, password) {
    /* let params = {
      client_id: this.constants.client_id,
      client_secret: this.constants.client_secret,
      username: username,
      password: password,
      grant_type: 'password'
    } */

    let parameters = new FormData();
    let clientCode = localStorage.getItem("clientCode");
    let isTokapp = localStorage.getItem("isTokapp");

    parameters.append('username',username);
    parameters.append('password',password);
    parameters.append('grant_type', 'password');

    return axios.post("/oauth/v2/token", parameters);
      // return axios.post("/token", parameters);

  },
  requestAnonimusToken(){
      let params = {
          grant_type:'client_credentials'
      }
    let parameters = new FormData();
    parameters.append('grant_type','client_credentials');
    var today = new Date();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
     return axios.post("/oauth/v2/token", parameters);
      // return axios.post("/token", parameters);
  },
  refreshToken(){
    let oauthInstance = axios.create();
    oauthInstance.defaults.baseURL = axios.defaults.baseURL;
    let parameters = new FormData();
    let clientCode = localStorage.getItem("clientCode");
    let isTokapp = localStorage.getItem("isTokapp");

    parameters.append('refresh_token', localStorage.refresh);
    parameters.append('grant_type','refresh_token');
    return   oauthInstance.post("/oauth/v2/token", parameters);
      // return   oauthInstance.post("/token", parameters);
  },
  checkIfChangePasswordTokenIsValid(userId, tempId, token){
    let parameters = {userId: userId, tempId: tempId, token: token};
    return axios.post("/changePassword/validate", parameters);
  }

}
