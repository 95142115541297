import axios from 'axios';
import entityHelper from "@/api/entityHelper";

export default {
    updateStage(id, code, name, startDate, endDate, commerceId = null, billingOfficialName = "", billingCctRvoe = "", year) {
        let parameters = new FormData();
        parameters.append('name', name);
        parameters.append('code', code);
        parameters.append('startDate', startDate);
        parameters.append('endDate', endDate);
        parameters.append('commerceId', commerceId);
        parameters.append('billingOfficialName', billingOfficialName);
        parameters.append('cctRvoe', billingCctRvoe);
        if(year != undefined){
            parameters.append('year', year);
        }
        return axios.post('/stage/' + id, parameters);
    },
    addStageAffection(centerId,entityId, year){
        let parameters = new FormData();
        parameters.append('centerId', centerId);
        parameters.append('entityId', entityId);
        if(year != undefined){
            parameters.append('year', year);
        }
        return axios.post('/affection/Stage',parameters);
    },
    addStage(code, name, type, centerId, affections, commerceId, year) {
        let parameters = new FormData();
        parameters.append('name', name);
        parameters.append('code', code);
        parameters.append('type', type);
        if(commerceId){
            parameters.append('commerceId', commerceId);
        }
        if(affections){
            parameters.append('affections',affections);
        }
        if (centerId) {
            parameters.append('centerId', centerId);
        }
        if(year != undefined){
            parameters.append('year', year);
        }
        return axios.post('/stage/', parameters);
    },
    deleteStage(id, year) {
        let url = '/stage/' + id;
        if (year !== undefined) {
            url += '?year=' + encodeURIComponent(year);
        }
        return axios.delete(url);
    },
    getStage(id, year) {
        let parameters = new FormData();
        if(year != undefined){
            parameters.append('year', year);
        }
        return axios.get("/stage/" + id, parameters);
    },
    updateCourseStage(id, code, name, startDate, endDate, year) {
        let parameters = new URLSearchParams() ;
        parameters.append('name', name);
        parameters.append('code', code);
        parameters.append('startDate', startDate);
        parameters.append('endDate', endDate);
        if(year != undefined){
            parameters.append('year', year);
        }
        return axios.post('/courseStage/' + id+'?'+parameters);
    },

    addCourseStage(code, name, parentId, startDate, endDate, studyId,affections,centerId, year) {
        let parameters = new FormData();
        parameters.append('name', name);
        parameters.append('code', code);
        parameters.append('startDate', startDate);
        parameters.append('endDate', endDate);

        if (affections != null) {
            parameters.append('affections',affections);
        }
        if (studyId != null) {
            parameters.append('study_id', studyId);
        } else {
            parameters.append('stage_id', parentId);
        }

        if (centerId != null) {
            parameters.append('center_id',centerId);
        }
        if(year != undefined){
            parameters.append('year', year);
        }

        return axios.post('/courseStage/', parameters);
    },
    addGroupCourseStage(code, name, parentId, year, individual = false) {
        let parameters = new FormData();
        parameters.append('name', name);
        parameters.append('code', code);
        parameters.append('coursestage_id', parentId);
        parameters.append('individual', individual);
        if(year != undefined){
            parameters.append('year', year);
        }

        return axios.post('/groupCourseStage/', parameters);
    },
    updateGroupCourse(id, code, name, year, individual = false) {
        let parameters = new FormData();
        parameters.append('name', name);
        parameters.append('code', code);
        parameters.append('individual', individual);
        if(year != undefined){
            parameters.append('year', year);
        }
        return axios.post('/groupCourseStage/' + id, parameters);
    },
    deleteGroupCourse(id, year) {
        let url = '/groupCourseStage/' + id;
        if (year !== undefined) {
            url += '?year=' + encodeURIComponent(year);
        }
        return axios.delete(url);
    },
    deleteStageGroup(id, year) {
        let url = '/courseStage/' + id;
        if (year !== undefined) {
            url += '?year=' + encodeURIComponent(year);
        }
        return axios.delete(url);
    },
    addStudy(code, name, parentId) {
        let parameters = new FormData();
        parameters.append('name', name);
        parameters.append('code', code);
        parameters.append('stage_id', parentId);
        return axios.post('/study', parameters);
    },
    updateStudy(id, code, name) {
        let parameters = new FormData();
        parameters.append('name', name);
        parameters.append('code', code);
        return axios.post('/study/' + id, parameters);
    },
    deleteStudy(id) {
        return axios.delete(/study/ + id);
    },
    getCoursesStage(stageId,yearCode) {
        // console.log('getCourseStage API - stageId',stageId,' yearCode: ',yearCode);
        let parameters = {};
        if(yearCode !== undefined) {
            parameters.year = yearCode;
        }

        if(stageId !== undefined){
            parameters.stage_id=stageId;
        }
        return axios.get('/coursestage', {params: parameters});

    },
    getProgramsCourseStage(courseStageId, year) {
        let parameters = new FormData();
        if(year != undefined){
            parameters.append('year', year);
        }
        if (courseStageId != undefined) {
            return axios.get('/program/?course_stage_id=' + courseStageId, parameters);
        }
    },
    deleteUfModule(programId) {
        if (programId != undefined) {
            return axios.delete('ufmodule/' + programId);
        }
    },
    getCoursesStudy(studyId) {
        if (studyId != undefined) {
            return axios.get('/study/' + studyId);
        }
    },
    getModulesStudy(studyId, onlyAviable) {
        let param;
        if (onlyAviable === true)
            param = '&onlyAviable=' + true;
        return axios.get('/ufmodule/?study_id=' + studyId + param);
    },
    getModulesStudyEnrolment(studyId) {
        return axios.get('studyModule/?study_id=' + studyId);
    },
    getAcademicStructure(type = "", schemaName = "", filters, year) {
        // console.log('getAcademicStructure');
        // console.log('API - getAcademicStructure.js',type,schemaName,filters,year);
        let queryString ='';
        let filtersQueryStr = '';
        if (schemaName) {
            queryString += '&changeYear='+schemaName;
        }
        if (type) {
            queryString += '&type='+type;
        }

        if(filters){
            if(typeof filters === 'object') {
                Object.entries(filters).forEach(([key, value]) => {
                    filtersQueryStr += '&' + key + '=' + value;
                });
                queryString += filtersQueryStr;
            }else{
                queryString += '&centers='+filters;
            }
        }

        if(year !== undefined){
            queryString += '&year='+year;
        }
        return axios.get('/academicstructure?'+ queryString);
    },
    getDropdownStructure(schemaName = "", onlyEnrol = 0){
        let params = {
            schemaName: schemaName,
            onlyEnrol: onlyEnrol
        };
        return axios.get('/academicstructure/dropdown', {params});
    },
    getStageAffections(year) {
        let params = new FormData();
        if(year != undefined){
            parameters.append('year', year);
        }
        return axios.get('/affection/stage', params);
    },
    getCommerceAffectations() {
        return axios.get('/affection/commerce');
    },
    addStageAffections(center, stageId, year) { //No s'utilitza a cap lloc
        let parameters = new FormData();
        parameters.append('stageId', stageId);
        parameters.append('center', center);
        if(year != undefined){
            parameters.append('year', year);
        }
        return axios.post('/stageAffectationAll?',  parameters);
    },
    removeStageAffections(center, stageId, year) {
        let parameters = new FormData();
        parameters.append('stageId', stageId);
        parameters.append('centerId', center);
        if(year != undefined){
            parameters.append('year', year);
        }
        return axios.delete('/stageAffectationAll?' + parameters);
    },
    getCourseStageAffections(){
        return axios.get('/courseStageAffection');
    },
    addCourseStageAffectation(centerId,courseStageId, year){
        let parameters = new FormData();
        parameters.append('centerId', centerId);
        parameters.append('courseStageId', courseStageId);
        if(year != undefined){
            parameters.append('year', year);
        }
        return axios.post('/courseStageAffection',parameters);
    },
    stageLocalToGlobal(stageId, year){
        let parameters = new FormData();
        if(year != undefined){
            parameters.append('year', year);
        }
        return axios.post('/stage/'+stageId+'/localToGlobal', parameters);
    },
    removeCourseStageAffectation(centerId,courseStageId, year){
        let parameters = new FormData();
        parameters.append('centerId', centerId);
        parameters.append('courseStageId', courseStageId);
        if(year != undefined){
            parameters.append('year', year);
        }
        return axios.delete('/courseStageAffection?'+parameters);
    },
    stageGlobalToLocal(stageId, year){
        let parameters = new FormData();
        if(year != undefined){
            parameters.append('year', year);
        }
        return axios.post('/stage/'+stageId+'/globalToLocal', parameters);
    },
    courseStageGlobalToLocal(courseStageId, year){
        let parameters = new FormData();
        if(year != undefined){
            parameters.append('year', year);
        }
        return axios.post('/courseStage/'+courseStageId+'/globalToLocal', parameters);
    },
    courseStageLocalToGlobal(courseStageId, year){
        let parameters = new FormData();
        if(year != undefined){
            parameters.append('year', year);
        }
        return axios.post('/courseStage/'+courseStageId+'/localToGlobal', parameters);
    },
    getGroupCourseStage(id){
        return axios.get('/groupCourseStage/'+id);
    },
    getCompleteName(stage, courseStage, groupCourseStage, year){
        let parameters = {}
        if(stage) parameters.stageId = stage;
        if(courseStage) parameters.courseStageId = courseStage;
        if(groupCourseStage) parameters.groupCourseStageId = groupCourseStage;
        if(year) parameters.year = year;
        return axios.get('/estructure/getCompleteName',{params:parameters});
    },
    getAllStage(){
        return axios.get('/stagesDropDown');
    },
    addCommerce(code, name, centerId, affections) {
        let parameters = new FormData();
        parameters.append('name', name);
        parameters.append('code', code);
        if(affections !== undefined){
            parameters.append('affections',affections);
        }

        if (centerId !== undefined) {
            parameters.append('centerId', centerId);
        }
        return axios.post('/commerce/', parameters);
    },
    deleteCommerce(id) {
        return axios.delete('/commerce/' + id);
    },
    updateCommerce(id, code, name, centerId) {
        let parameters = new FormData();
        parameters.append('name', name);
        parameters.append('code', code);
        parameters.append('centerId', centerId);
        return axios.post('/commerce/' + id, parameters);
    },
    changePositions(positions, year){
        let parameters = new FormData();
        if(year !== undefined){
            parameters.append('affections',year);
        }
        return axios.post('/academicstructure/changePositions', {positions, parameters});
    },
    commerceGlobalToLocal(commerceId){
        return axios.post('/commerce/'+commerceId+'/globalToLocal');
    },
    commerceLocalToGlobal(commerceId){
        return axios.post('/commerce/'+commerceId+'/localToGlobal');
    },
    getCommerceAffections(){
        return axios.get('/commerceAffectation');
    },
    addCommerceAffection(centerId,entityId){
        let parameters = new FormData();
        parameters.append('centerId', centerId);
        parameters.append('entityId', entityId);
        return axios.post('/affection/Commerce',parameters);
    },
    removeCommerceAffections(center, commerceId) {
        let parameters = new FormData();
        parameters.append('commerceId', commerceId);
        parameters.append('centerId', center);
        return axios.delete('/commerceAffectationAll?' + parameters);
    },
    getCommerces(){
        return axios.get('/commercesDropDown');
    },
}
