import authApi from '../api/core/auth'
import { now } from 'moment'
import axios from 'axios'
import router from '../router'
import Users from '@/api/core/users'
import i18n from '@/translations';
import api2FA from '@/api/core/oAuth2fa';

let subscribers = []
let isAlreadyFetchingAccessToken = false

let subscribersAnonymous = []

function isRoute (to) {
    return function (router) {
        return router.name == to
    }
}

export default {

    setToken (access_token, refresh_token, anonymous, expireTime) {
        //console.log('save access_toke into storage')
        sessionStorage.setItem('token', access_token)
        localStorage.setItem('refresh', refresh_token)
        localStorage.anonymous = anonymous
        let date = new Date()
        localStorage.expireTime = date.getTime() + ((expireTime - 3) * 1000)
        //console.log("setExpireTime 1",date.getTime(), expireTime,  date.getTime() + ((expireTime - 3) * 1000));
        if (!anonymous) {
            //setejem la cookie de control
            console.log('neteja de cookie ieduca.com')
            this.setCookie('ieduca.com', now(), 90) // per 2fa
        }
    },
    addSubscriber (callback) {
        subscribers.push(callback)
    },
    onAccessTokenFetched (access_token) {
        // console.log("onAccessTokenFetched", subscribers);
        subscribers = subscribers.filter(callback => callback(access_token))
    },
    addSubscriberAnonymous (callback) {
        subscribersAnonymous.push(callback)
    },
    onAccessTokenFetchedAnonymous (access_token) {
        // console.log("onAccessTokenFetched", subscribersAnonymous);
        subscribersAnonymous = subscribersAnonymous.filter(callback => callback(access_token))
    },
    managerRefreshToken () {
        //console.log('************ managerRefreshToken')
        if (!isAlreadyFetchingAccessToken) { // TODO: Si és anonima o skyped no cal fer això
            //console.log('demana nou managerRefreshToken ')
            isAlreadyFetchingAccessToken = true
            authApi.refreshToken().then(access_token => {
                //console.log('RefreshToken resposta correcta')
                isAlreadyFetchingAccessToken = false
                this.setToken(access_token.data.access_token, access_token.data.refresh_token, false, access_token.data.expires_in)
                this.onAccessTokenFetched(access_token)
            }).catch(error => {
                //console.log('RefreshToken error', error)
                if (error.hasOwnProperty('status') && error.status !== 455 && error.status !== 401) {
                    //si falla el refresh l'única opció que té es tornar a posar usuari i password.
                    router.push('/logout')
                } else if (error.hasOwnProperty('message') && (error.message == 'Request failed with status code 400' || error.message == 'Request failed with status code 403')) {
                    //console.log('refreshError redirectlogin')
                    // =========================================================== //
                    // Si no tinc access_token ni tampoc refresh_token no puc fer logout, forçem login.
                    // Caldria que el logout fos d'accés anònim.
                    router.push('/login').catch(e => {})
                    // =========================================================== //
                }
            })
        }else{
        // console.log('managerRefreshToken isAlreadyFetchingAccessToken');
      }
    },
    setCookie (cname, value, expiresDays) {
        let d = new Date()
        d.setTime(d.getTime() + (expiresDays * 24 * 60 * 60 * 1000))
        let expires = 'expires=' + d.toUTCString()
        document.cookie = cname + '=' + value + ';' + expires + ';path=/'
    },
    deleteCookie (cname) {
        this.setCookie(cname, '', -1)
    },
    existCookie (name) {
        let cookie = this.getCookie(name)
        return cookie !== ''
    },
    getCookie (cname) {
        let name = cname + '='
        let ca = document.cookie.split(';')
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i]
            while (c.charAt(0) == ' ') {
                c = c.substring(1)
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length)
            }
        }
        return ''
    },
    hasPermissionOnRoute (abilities, route) {
        //console.log("********* abilities : ",abilities);
        if (ability.rules.length > 0) {
            if (route.hasOwnProperty('meta') && route.meta.hasOwnProperty('action') && route.meta.hasOwnProperty('resource')) {
                if (!abilities.can(route.meta.action, route.meta.resource)) {
                    console.log('error', route)
                    router.push('error')
                }
            }
        }
    },
    hasPermission (abilities, to, router) {
        let routes = router.filter(isRoute(to))
        if (routes.length > 0) {
            this.hasPermissionOnRoute(abilities, routes[0])
        }
    },
    async login (username, password, store, alreadyValidated2FA = false, redirectUrl = false) {
        // console.log('login', username, password, store, alreadyValidated2FA, redirectUrl);
        localStorage.removeItem('refresh')
        localStorage.removeItem('anonymous')
        localStorage.removeItem('expireTime')
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('clientCode');
        localStorage.removeItem('isTokapp');
        let self = this;
        await authApi.requestToken(username, password).then(async response => {
            sessionStorage.removeItem('token')
            //Esborrar el menu quan fem login
            sessionStorage.removeItem('menu');
            sessionStorage.removeItem('menuConfig');
            let data
            if (response.data === undefined && response.response.data !== undefined) {
                data = response.response.data
                if (data.error == 'user.No.trustedDevice') {
                    return
                }
            }
            else if(response.data.error === "user.password.expire"){
                //Si cal modificar la contrasenya ja ni comprovem si el 2fa està actiu
                this.normalLogin(response, store)
            }
            else {
                //COMPROVAR SI TÉ EL 2FA ACTIU
                sessionStorage.setItem('token', response.data.access_token)
                await api2FA.check2FAIsActive().then((response2) => {
                    if (response2.data.status === 'ok') {
                      let active2fa = response2.data.data.is2fa;
                      let isTrustedDevice = response2.data.data.isTrusted
                      if(active2fa && !alreadyValidated2FA && !isTrustedDevice){ //SI TE ACTIU EL 2FA I NO ESTÀ VALIDAT CAL REDIRECCIONAR A LA PANTALLA DEL 2FA
                        localStorage.setItem('username', username);
                        localStorage.setItem('password', password);
                        router.push('/twoFactor');
                      }
                      else{ //SI NO TE 2FA ACTIU O EL TE ACTIU PERÒ JA ESTA VALIDAT CAL FER LOGIN NORMAL
                        this.normalLogin(response, store, redirectUrl)
                      }
                    } else{
                        //TODO: ERROR DE BACK
                        router.push('/')
                    }
                });
            }
        }, error => {
            store.commit('setAuthError', true)
            store.commit('setError', 'error.userPassword')
        })
    },
    normalLogin(responseData, store, redirectUrl = false){
        let data = responseData.data
        // console.log('Normal login data', data);
        this.setToken(data.access_token, data.refresh_token, false, data.expires_in)
        // Add an axios filter to set a header with the token
        axios.interceptors.request.use(function (config) {
            config.headers['Authorization'] = 'Bearer ' + sessionStorage.token;
//              config.cancelToken = state.cancelToken.token;
            return config
        })
        localStorage.setItem('isLoggedIn', "1");
        Users.getPermissions(true).then((response) => {
            if (response && 'data' in response && Array.isArray(response.data)) {
                ability.update(response.data);
            }
        })
        Users.getLang().then(response => {
            let lang = response.data.lang;

            if(lang){
                lang.toLowerCase();
                localStorage.setItem("language", lang);
                //console.log("setLanguage", lang);
                i18n.locale = lang;
            }
        });
        store.dispatch('currentPersonRoles', {force: true});
        // console.log('Normal login', redirectUrl)
        //si es de tipus user.password.expire ja s'ha redirigit cap a restablir el password.
        if (data.error == 'user.password.expire') return;

        // redirigim a la home si no rebem una url especifica
        if(redirectUrl){
            Users.getCurrentUser().then(response => {
                let personId = response.data.user.person.id
                router.push(redirectUrl.replace('userId', personId))
            })
        }else{
            router.push('/')
        }

    },
    async loginPreregistration (username, password, store) {
        localStorage.removeItem('refresh');
        localStorage.removeItem('anonymous');
        localStorage.removeItem('expireTime');
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('clientCode');
        localStorage.removeItem('isTokapp');        
        let resultMessage = 'ok';
        await authApi.requestToken(username, password).then(response => {
            sessionStorage.removeItem('token');
            if (response.data === undefined && response.response.data !== undefined) {
                let data = response.response.data;
                if (data.error === 'user.No.trustedDevice') {
                    resultMessage = 'ko';
                    return;
                }
                else{
                    resultMessage = 'ko'
                }
            } else {
                let data = response.data;
                this.setToken(data.access_token, data.refresh_token, false, data.expires_in);
                localStorage.setItem('isLoggedIn', "1");
                // Add an axios filter to set a header with the token
                axios.interceptors.request.use(function (config) {
                    config.headers['Authorization'] = 'Bearer ' + sessionStorage.token;
                    return config
                });
            }
        }).catch(error => {
            store.commit('setAuthError', true)
            store.commit('setError', 'error.userPassword')
            resultMessage = 'ko'
        });
        return resultMessage;
    },
    async loginWithTokens (access_token, refresh_token, expireTime, clientCode) {
        this.setToken(access_token, refresh_token, false, expireTime)
        localStorage.setItem('isLoggedIn', "1");
        localStorage.setItem("isTokapp", "1");
        localStorage.setItem("clientCode", clientCode);
        
        axios.interceptors.request.use(function (config) {
            config.headers['Authorization'] = 'Bearer ' + sessionStorage.token
            return config;
        })
        let response = await Users.getPermissions(true);
        if (response && 'data' in response && Array.isArray(response.data)) {
            ability.update(response.data);
        }
        let responseLang = await Users.getLang();
        if(responseLang && 'data' in response && responseLang.data.lang){
            let lang = responseLang.data.lang;
            lang.toLowerCase();
            localStorage.setItem("language", lang);
            i18n.locale = lang;
        }
        return router.push('/')
    }
}

