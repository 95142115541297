import Vue from 'vue';
import Router from 'vue-router';
import store from '../store/store';
import securityHelper from '../utils/securityHelper';
import { EventBus } from '@/eventBus';

Vue.use(Router);

let router = new Router({
    mode: 'history',
    linkActiveClass: 'active',
    routes: [
        {
            path: '/',
            name: 'root',
            component: () => import('@/components/Home')
        },
        {
            path: '/',
            name: 'config',
            redirect: '/profile'
        },
        {
            path: '/error',
            name: 'error',
            component: () => import('@/components/Error'),
            props: true
        },
        {
            path: '/login',
            name: 'Login',
            component: () => import('@/core/Login'),
            props: true
        },
        {
            path: '/logout',
            name: 'Logout',
            component: () => import('@/core/Login')
        },
        {
            path: '/spaces',
            name: 'spaces',
            component: () => import('@/core/crud/spaces/SpaceList'),
            meta: {
                action: 'view',
                resource: 'Spaces'
            }
        },
        {
            path: '/areas',
            name: 'areas',
            component: () => import('@/core/crud/areas/AreaList'),
            meta: {
                action: 'view',
                resource: 'Areas'
            }
        },
        {
            path: '/subjects',
            name: 'subjects',
            component: () => import('@/core/crud/subjects/Subjects'),
            props: true,
            meta: {
                action: 'view',
                resource: 'Subjects'
            }
        },
        {
            path: '/subjects/new',
            name: 'SubjectsNew',
            component: () => import('@/core/crud/subjects/SubjectForm'),
            meta: {
                action: 'edit',
                resource: 'Subjects'
            }
        },
        {
            path: '/subjects/:subjectId/programId=:programId?&showProgramForm=:showProgramForm?',
            name: 'SubjectsEdit',
            component: () => import('@/core/crud/subjects/SubjectForm'),
            props: true,
            meta: {
                action: 'view',
                resource: 'Subjects'
            }
        },
        {
            path: '/subject-module/new',
            name: 'ModuleSubjectsNew',
            component: () => import('@/core/crud/subjects/ModuleForm'),
            props: true
        },
        {
            path: '/subject-module/:moduleId',
            name: 'ModuleSubjectsEdit',
            component: () => import('@/core/crud/subjects/ModuleForm'),
            props: true
        },
        {
            path: '/subject-module/:moduleId/subject-unit/new',
            name: 'UnitSubjectsNew',
            component: () => import('@/core/crud/subjects/UnitForm'),
            props: true
        },
        {
            path: '/subject-module/:moduleId/subject-unit/:unitId',
            name: 'UnitSubjectsEdit',
            component: () => import('@/core/crud/subjects/UnitForm'),
            props: true
        },
        {
            path: '/recover/password',
            name: 'RecoverPassword',
            component: () => import('@/core/security/RecoverPassword'),
            props: true
        },
        {
            path: '/change/password',
            name: 'ChangePassword',
            component: () => import('@/core/security/ChangePassword'),
            props: true
        },
        {
            path: '/import',
            name: 'import',
            component: () => import('@/core/import/ImportIndex'),
            meta: {
                action: 'view',
                resource: 'Importall'
            }
        },
        {
            path: '/import/upload/:entityParam',
            name: 'ImportChooseFile',
            component: () => import('@/core/import/ChooseFile'),
            props: true,
            meta: {
                action: 'view',
                resource: 'Importall'
            },
            children: [
                {
                    // path: '/classes/students/:date?/:timeId?/:groupId?/:subjectId?/:teacherId?/:courseStageId?',
                    path: '/import/upload/competence/params',
                    name: 'ImportCompetenceParams',
                    component: () => import('@/core/import/params/ImportCompetenceParams.vue'),
                },
            ]
        },
        {
            path: '/import/matching',
            name: 'ImportMatching',
            component: () => import('@/core/import/Matching'),
            meta: {
                action: 'view',
                resource: 'Importall'
            }
        },
        {
            path: '/import/preview',
            name: 'ImportPreview',
            component: () => import('@/core/import/Preview'),
            meta: {
                action: 'view',
                resource: 'Importall'
            }
        },
        {
            path: '/import/result',
            name: 'ImportResult',
            component: () => import('@/core/import/Result'),
            meta: {
                action: 'view',
                resource: 'Importall'
            }
        },
        {
            path: '/profile',
            name: 'Profile',
            component: () => import('@/core/person/Person'),
            props: {profile: true},
            meta: {
                action: 'view',
                resource: 'Profile'
            }
        },
        {
            path: '/persons/:personId?',
            name: 'persons',
            component: () => import('@/core/person/Persons'),
            props: true,
            meta: {
                action: 'view',
                resource: 'Persons'
            }
        },
        {
            path: '/person/:personId?',
            name: 'person',
            component: () => import('@/core/person/Person'),
            props: true,
        },
        {
            path: '/person',
            name: 'AddPerson',
            component: () => import('@/core/person/Person'),
            props: true,
            meta: {
                action: 'view',
                resource: 'Persons'
            }
        },
        {
            path: '/academicstructure',
            name: 'academicstructure',
            component: () => import('@/core/crud/academicstructure/AcademicStructureMain'),
            props: true,
            meta: {
                action: 'view',
                resource: 'Academstruc'
            }
        },
        {
            path: '/academicstructure/groupCourseStudents/:stageId?/:courseStageId?/:groupCourseStageId?',
            name: 'groupcoursestudents',
            component: () => import('@/core/crud/academicstructure/GroupCourseStudents'),
            props: (route) => ({
                stageId: route.params.stageId || route.query.stageId,
                courseStageId: route.params.courseStageId || route.query.courseStageId,
                groupCourseStageId: route.params.groupCourseStageId || route.query.groupCourseStageId,
                year: route.query.year || null // set default value for year if not provided
            }),
            meta: {
                action: 'view',
                resource: 'Academstruc'
            }
        },
        {
            path: '/roles',
            name: 'roles',
            component: () => import('@/core/crud/roles/RoleList'),
            meta: {
                action: 'view',
                resource: 'Roles'
            },
            props: true,
        },
        {
            path: '/roles/:roleId?',
            name: 'roleadd',
            component: () => import('@/core/crud/roles/RoleEdit'),
            props: true,
            meta: {
                action: 'view',
                resource: 'Roles'
            }
        },
        {
            path: '/centerprofile',
            name: 'centerprofile',
            component: () => import('@/core/center/Center'),
            meta: {
                action: 'view',
                resource: 'Centerprofile'
            }
        },
        {
            path: '/academicyear',
            name: 'academicyear',
            component: () => import('@/core/crud/academicyear/AcademicYearList'),
            meta: {
                action: 'view',
                resource: 'Academicyear'
            }
        },
        {
            path: '/modules',
            name: 'modules',
            component: () => import('@/core/center/Modules'),
            meta: {
                action: 'view',
                resource: 'Modules'
            }
        },
        {
            path: '/parameters/:moduleId?',
            name: 'parameters',
            component: () => import('@/core/crud/parameters/ParameterList'),
            props: true,
            meta: {
                action: 'view',
                resource: 'Parameter'
            }
        },
        {
            path: '/studentsEnrollment/:stageId?/:courseStageId?/:groupCourseStageId?',
            name: 'studentsEnrollment',
            component: () => import('@/core/crud/enrollments/GridStudentsEnrollment'),
            props: true,
            meta: {
                action: 'view',
                resource: 'Enrolment'
            }
        },
        {
            path: '/calendar/:groupId?',
            name: 'calendar',
            component: () => import('@/calendar/MainCalendarView'),
            props: true,
            meta: {
                action: 'view',
                resource: 'Calendar'
            }
        },
        {
            path: '/calendars',
            name: 'calendars',
            component: () => import('@/calendar/CalendarManager'),
            meta: {
                action: 'view',
                resource: 'Calendar'
            }
        },
        {
            path: '/calendars/:calendarId',
            name: 'calendarView',
            component: () => import('../calendar/HolidaysEditor'),
            props: true,
            meta: {
                action: 'view',
                resource: 'Calendar'
            }
        },
        {
            path: '/tags',
            name: 'tags',
            component: () => import('@/core/crud/tags/TagList'),
            meta: {
                action: 'view',
                resource: 'Tags'
            }
        },
        {
            path: '/enrolments',
            name: 'enrolments',
            component: () => import('@/core/crud/enrollments/Enrolments'),
            meta: {
                action: 'view',
                resource: 'Enrolment'
            }
        },
        {
            path: '/enrolment/:enrolmentId?/:schema?',
            name: 'AddEnrolmentProcess',
            component: () => import('@/core/crud/enrollments/AddEnrolmentProcess'),
            props: true,
            meta: {
                action: 'edit',
                resource: 'Enrolment'
            }
        },
        {
            path: '/groups',
            name: 'groups',
            component: () => import('@/core/crud/groups/GroupList'),
            meta: {
                action: 'view',
                resource: 'Groups'
            }
        },
        {
            path: '/timetabledrafts',
            name: 'timetabledrafts',
            component: () => import('@/timetable/TimetableTimeline'),
            meta: {
                action: 'edit',
                resource: 'Timetable'
            }
        },
        {
            path: '/timetabledef',
            name: 'timetabledef',
            component: () => import('@/timetable/TimetableDef'),
            props: true,
            meta: {
                action: 'view',
                resource: 'Timetable'
            }
        },
        {
            path: '/functionalityTemplates',
            name: 'functionalityTemplates',
            component: () => import('@/templates/TemplateList'),
            meta: {
                action: 'view',
                resource: 'Template'
            }
        },
        {
            path: '/template',
            name: 'AddTemplate',
            component: () => import('@/templates/Template'),
            props: true,
            meta: {
                action: 'edit',
                resource: 'Template'
            }
        },
        {
            path: '/template/:templateId',
            name: 'EditTemplate',
            component: () => import('@/templates/Template'),
            props: true,
            meta: {
                action: 'view',
                resource: 'Template'
            }
        },
        {
            path: '/twoFactor',
            name: 'twoFactor',
            component: () => import('@/core/security/TwoFactor'),
            meta: {
                action: 'view',
                resource: '2fa'
            }
        },
        {
            //?classId=:classId?&competenceId=:competenceId?&courseStageId=:courseStageId?&date=:date?&groupId=:groupId?&sessionId=:sessionId?&teacherId=:teacherId?&seeAll=:seeAll?',
            path: '/classes',
            component: () => import('@/core/crud/classes/Classes'),
            props: true,
            children: [
                {
                    // path: '/classes/students/:date?/:timeId?/:groupId?/:subjectId?/:teacherId?/:courseStageId?',
                    path: '/classes/students',
                    name: 'classStudents',
                    component: () => import('@/core/crud/classes/Students'),
                    props: true,
                    meta: {
                        action: 'view',
                        resource: 'Classstudents'
                    }
                },
                {
                    // path: '/classes/attendance/:date?/:timeId?/:groupId?/:subjectId?/:teacherId?/:courseStageId?',
                    path: '/classes/attendance',
                    name: 'attendance',
                    component: () => import('@/core/crud/attendance/AttendanceList'),
                    props: true,
                    meta: {
                        action: 'view',
                        resource: 'Attendance'
                    }
                },
                {
                    path: '/classes/followup',
                    name: 'classFollowup',
                    component: () => import('@/core/crud/classes/ClassFollowup'),
                    props: true,
                    meta: {
                        action: 'view',
                        resource: 'Classcontents'
                    }
                },
                {
                    // path: '/classes/students_comments/:student?/:date?/:timeId?/:groupId?/:subjectId?/:teacherId?/:courseStageId?',
                    path: '/classes/students_comments',
                    name: 'classStudentsComments',
                    component: () => import('@/core/crud/classes/ClassStudentsComments'),
                    props: true,
                    meta: {
                        action: 'view',
                        resource: 'Classstudentcomment'
                    }
                },
                {
                    // path: '/classes/grades/grid/:date?/:timeId?/:groupId?/:subjectId?/:teacherId?/:courseStageId?',
                    path: '/classes/grades/grid',
                    name: 'classGrades',
                    component: () => import('@/grades/GradesGrid'),
                    props: true,
                },
                {
                    path: '/classes/documents',
                    name: 'classDocuments',
                    component: () => import('@/core/crud/classes/ClassDocuments'),
                    props: true
                },
                {
                    path: '/classes/planning',
                    name: 'classContents',
                    component: () => import('@/core/crud/classes/ClassPlanning'),
                    props: true,
                    meta: {
                        action: 'view',
                        resource: 'Program'
                    }
                },
                {
                    path: '/classes/calendar',
                    name: 'classCalendar',
                    component: () => import('@/calendar/MainCalendarView'),
                    props: true,
                    meta: {
                        action: 'view',
                        resource: 'Calendar'
                    }
                },
            ]
        },
        {
            path: '/timetabledraft/:draftId/timetableActiveId=:timetableActiveId?/schemaName=:schemaName?/beforeDate=:beforeDate?',
            name: 'EditTimetable',
            component: () => import('@/timetable/EditTimetable'),
            props: true,
            meta: {
                action: 'edit',
                resource: 'Timetable'
            }
        },
        {
            path: '/timetable/slots/schemaName=:schemaName?',
            name: 'TimetableSlots',
            component: () => import('@/timetable/TimetableSlots'),
            props: true,
            meta: {
                action: 'edit',
                resource: 'Timetable'
            }
        },
        {
            path: '/timetable/categories/schemaName=:schemaName?',
            name: 'TimetableCategories',
            component: () => import('@/timetable/TimetableCategories'),
            props: true,
            meta: {
                action: 'edit',
                resource: 'Timetable'
            }
        },
        {
            path: '/communications/:action?',
            name: 'communications',
            component: () => import('@/messaging/Communications'),
            props: true,
            meta: {
                action: 'view',
                resource: 'Communications'
            }
        },
        {
            path: '/communicationsConfig/:action?',
            name: 'communicationsConfig',
            component: () => import('@/messaging/CommunicationsConfig'),
            props: true,
            meta: {
                action: 'edit',
                resource: 'Communications'
            }
        },
        {
            path: '/webhooks/helpex',
            name: 'helpexConfig',
            component: () => import('@/billing/BillingHome'),
            props: true,
            meta: {
                action: 'edit',
                resource: 'Communications'
            }
        },

        {
            path: '/communication/view/:id/:folder/:year?',
            name: 'viewMessageCommunication',
            component: () => import('@/messaging/ViewMessageCommunication'),
            props: true,
            meta: {
                action: 'view',
                resource: 'Communications'
            }
        },
        {
            path: '/communication/edit',
            name: 'composeCommunication',
            component: () => import('@/messaging/ComposeCommunication'),
            props: true,
            meta: {
                action: 'edit',
                resource: 'Communications'
            }
        },
        {
            path: '/messaging/edit',
            name: 'composeInternal',
            component: () => import('@/messaging/ComposeInternal'),
            props: true,
            meta: {
                action: 'edit',
                resource: 'Messaging'
            }
        },
        {
            path: '/messaging/:defaultFolder?',
            name: 'messaging',
            component: () => import('@/messaging/Messaging'),
            props: true,
            meta: {
                action: 'view',
                resource: 'Messaging'
            }
        },
        {
            path: '/messaging/view/:id/:folder/:year?',
            name: 'ViewMessageInternal',
            component: () => import('@/messaging/ViewMessageInternal'),
            props: true,
            meta: {
                action: 'view',
                resource: 'Messaging'
            }
        },
        {
            path: '/messagingList',
            name: 'MessageListRegistered',
            component: () => import('@/messaging/MessageListRegistered'),
            props: true,
            meta: {
                action: 'view',
                resource: 'Messaging'
            }
        },
        {
            path: '/signup',
            name: 'signup',
            component: () => import('../core/Preinscriptions/SignUp'),
            props: false,
        },
        {
            path: '/attendance/report',
            name: 'ReportAttendanceFilters',
            component: () => import('@/core/crud/attendance/ReportAttendanceFilters'),
            props: true,
            meta: {
                action: 'view',
                resource: 'Attendancereport'
            }
        },
        {
            path: '/attendanceconfig/:tabActive?',
            name: 'attendanceconfig',
            component: () => import('@/core/crud/attendance/attendanceConfig'),
            props: true,
            meta: {
                action: 'edit',
                resource: 'Attendance'
            }
        },
        {
            path: '/followupConfig/:tabActive?',
            name: 'moduleFollowup',
            component: () => import('@/core/crud/classes/ClassFollowupConfig.vue'),
            props: true,
            meta: {
                action: 'edit',
                resource: 'Classplanning'
            }
        },
        {
            path: '/timetabledraft/:draftId/timetableActiveId=:timetableActiveId?/history/schemaName=:schemaName?/beforeDate=:beforeDate?',
            name: 'TimetableHistory',
            component: () => import('../timetable/TimetableHistory'),
            props: true,
        },
        {
            path: '/preregistration/detail/:idPreregistration?/:yearCode?',
            name: 'preregistrationDetail',
            component: () => import('../preregistration/PreregistrationPage'),
            props: true,
            meta: {
                action: 'view',
                resource: 'Preregistration'
            }
        },
        {
            path: '/preregistration',
            name: 'preregistration',
            component: () => import('../preregistration/PreregistrationList/PreregistrationList'),
            props: true,
            meta: {
                action: 'view',
                resource: 'Preregistration'
            }
        },
        {
            path: '/preregistrationOpen',
            name: 'preregistrationOpen',
            component: () => import('../preregistration/public/PreregistrationOpenPage')
        },
        {
            path: '/preregistrationOpenSignUp/:preregistrationId?/:centerCode?/:yearCode?',
            name: 'preregistrationOpenSignUp',
            component: () => import('../preregistration/public/PreregistrationOpenDescription'),
            props: true
        },{
            path: '/preregistrationDescription/:preregistrationId?/:centerCode?/:yearCode?',
            name: 'preregistrationDescription',
            component: () => import('../preregistration/public/PreregistrationOpenDescription'),
            props: true
        },
        {
            path: '/preregistrationProcess/:preregistrationId?/:personIdProp?/:yearCode?/:centerCode?',
            name: 'preregistrationProcess',
            component: () => import('../preregistration/PreregistrationProcess/PreregistrationProcess'),
            props: true
        },
        {
            path: '/grades',
            name: 'grades',
            component: () => import('@/grades/GradesMenu'),
            props: true,
        },
        {
            path: '/grades/newsletters/:year?/:groupCourseStage?/:evaluationPeriod?',
            name: "newsletters",
            component: () => import('@/grades/GradesNewsletters'),
            props: true,
        },
        {
            path: '/grades/comments/',
            name: "personalComments",
            component: () => import('@/grades/PredefinedComments'),
            props: true,
            meta: {
                action: 'view',
                resource: 'Commentdefaultown'
            }
        },
        {
            path: '/gradesConfig',
            name: 'gradesConfig',
            component: () => import('@/grades/GradesConfig'),
            children: [
                {
                    path: '/gradesConfig/assignConvocatories',
                    name: "assignConvocatories",
                    component: () => import('@/grades/GradesAssignConvocatories'),
                    meta: {
                        action: 'edit',
                        resource: 'Gradesconfig'
                    }
                },
                {
                    path: '/gradesConfig/evaluationPeriods',
                    name: "evaluationPeriods",
                    component: () => import('@/grades/GradesEvaluationPeriods'),
                    meta: {
                        action: 'edit',
                        resource: 'Gradesconfig'
                    }
                },
                {
                    path: '/gradesConfig/competences',
                    name: "competences",
                    component: () => import('@/grades/Competences'),
                    meta: {
                        action: 'edit',
                        resource: 'Competence'
                    }
                },
                {
                    path: '/gradesConfig/assignCompetences',
                    name: "assignCompetences",
                    component: () => import('@/grades/GradesAssignCompetences'),
                    meta: {
                        action: 'edit',
                        resource: 'Gradesconfig'
                    }
                },
                {
                    path: '/gradesConfig/ambits',
                    name: "ambits",
                    component: () => import('@/grades/AmbitSubjects'),
                    meta: {
                        action: 'edit',
                        resource: 'Ambit'
                    }
                },
                {
                    path: '/gradesConfig/comments',
                    name: "configComments",
                    component: () => import('@/grades/PredefinedComments'),
                    meta: {
                        action: 'view',
                        resource: 'Commentdefault'
                    }
                },
                {
                    path: '/gradesConfig/commentCategories',
                    name: "commentcategories",
                    component: () => import('@/grades/PredefinedCommentCategories'),
                },
            ],
            props: false,
            meta: {
                action: 'edit',
                resource: 'Gradesconfig'
            }
        },
        {
            path: '/gradesConfig/parameters',
            name: "gradesConfigParameters",
            props: true,
            component: () => import('@/grades/GradesConfigParameters/GradesConfigParameters'),
        },
        {
            path: '/PreregistrationOpenSignUpSuccess',
            name: 'preregistrationSuccess',
            component: () => import('../preregistration/public/PreregistrationOpenSignUpSucces')
        },
        {
            path: '/Preregistration/Person/List',
            name: 'preregistrationPerson',
            component: () => import('../preregistration/PreregistrationPersonListPage')
        }, //EditPreregistrationPerson
        {
            path: '/Preregistration/Person/Edit/:preregistrationPersonId/:yearCode?',
            name: 'preregistrationPersonEdit',
            component: () => import('../preregistration/EditPreregistrationPerson'),
            props:true
        },
        {
            path: '/Preregistration/tempPersons',
            name: 'preregistrationTempPersons',
            component: () => import('../preregistration/PreregistrationTempPersons')
        },
        {
            path: '/billing',
            name: 'billing',
            component: () => import('@/billing/BillingHome'),
            props: true,
        },
        {
            path: '/client',
            name: 'client',
            component: () => import('@/core/client/Client'),
            meta: {
                action: 'view',
                resource: 'Client'
            }
        },
        {
            path: '/program/:programId',
            name: 'ProgramForm',
            component: () => import('@/core/crud/subjects/SubjectProgramForm'),
            props: true,
            meta: {
                action: 'view',
                resource: 'Program'
            }
        },
        {
            path: '/myDocuments',
            name: 'myDocuments',
            component: () => import('@/core/crud/mydocuments/MyDocuments'),
        },
        {
            path: '/gradesStudent/:propStudentId?/:enrolmentId?/:yearCode?',
            name: 'GradesStudent',
            component: () => import('@/grades/GradesStudent/GradesStudent'),
            props: true,
            meta: {
                action: 'view',
                resource: 'Parentgrades'
            }
        },
        {
            path: '/student/:propStudentId/comments/:groupId?',
            name: 'studentAllComments',
            component: () => import('@/core/crud/classes/StudentComments.vue'),
            props: true,
            meta: {
                action: 'view',
                resource: 'Studentcomment'
            }
        },
    ]
});


router.beforeEach((to, from, next) => {
    //console.log("TO: ", to.path);
    if (to.path === '/logout') {
        store.commit('deleteMenus');
        store.commit('resetUserInfo');
        store.dispatch('resetCenterInfo');
        store.dispatch('logout').then(function () { // borrem la sessio i enviem l'usuari a fer login
            next('/login');
        });
    } else if(to.path === '/twoFactor' && !localStorage.getItem("username")){
        next('/login');
    }
    else {
        // ========================================================== //
        // Cancel·lar peticions anteriors (potser caldria fer-ho de manera individual en comptes de general
        // o trobar un sistema alternatiu). Fer més proves amb aquesta part descomentada.

        //console.log('index.js - before cancelToken: ',store.state.auth.cancelToken.token," to: ",to.name);
        // store.state.auth.cancelToken.cancel();
        //console.log('index.js - before dispatch to: ',to.name);
        store.dispatch('generateCancelToken');
        //console.log('index spliceToName: ',to);
        store.commit('spliceToName', to.name);
        //console.log('index.js hasPermissionOnRoute');
        securityHelper.hasPermissionOnRoute(ability, to);
        //console.log('index.js next');
        next();
    }
    if(localStorage.getItem('needsToDeployNewVersion')){
        localStorage.removeItem('needsToDeployNewVersion')
        EventBus.$emit('deployNewVersion', {});
    }
});


export default router;
