import axios from 'axios';
import entityHelper from '@/api/entityHelper'

export default {

    getAvailableCalendars(idOnly = false) {
        return axios.get('/calendar/list', {params: {onlyIds: idOnly}});
    },
    save(calendar) {
        return axios.post('/calendar/' + calendar.id, {calendar});
    },
    delete(calendar) {
        return axios.delete('/calendar/' + calendar.id);
    },
    saveEvent(event, calendarId, repetitionDefinition, mode) {
        // console.log("Saving event in calendar " + calendarId);
        event.start = event.start.format("YYYY-MM-DD HH:mm");
        event.end = event.end.format("YYYY-MM-DD HH:mm");
        const eventId = event.id > 0 ? event.id : 0;
        //console.log("event: ", event, "calendar_id: ", calendarId, "repetition: ",  repetitionDefinition, "mode: ", mode);
        return axios.post('/calendar/event/' + eventId, {
            event: event,
            calendar_id: calendarId,
            repetition: repetitionDefinition,
            mode: mode
        })
    },
    getCategories(calendarId) {
        return axios.get('/calendar/categories/' + calendarId)
    },
    getCalendar(id) {
        return axios.get('/calendar/' + id)
    },
    getEvent(id) {
        return axios.get('/calendar/event/' + id)
    },
    getEvents(calendars, start, end, eventLimit, indexedArray = 0) {
        let calendarList = calendars != 'pending' ? calendars.map(c => c.id) : [];
        let params = {
            'calendars': calendarList.join(','),
            'start': Math.trunc(start.getTime() / 1000),
            'end': Math.trunc(end.getTime() / 1000),
            'indexedArray': indexedArray,
            'eventLimit': eventLimit
        };
        return axios.get('/calendar/events', {params});
    },
    getSystemCalendars(filters, page, pageSize, orderField, orderMode) {
        return entityHelper.findEntity("/calendars", filters, page, pageSize, orderField, orderMode);
    },
    removeEvent(event, mode) {
        return axios.delete('/calendar/event/' + event.id + "/" + mode);
    },
    getGroupCalendar(groupId, classeId, withHomework, color = null) {
        let params = {
            'groupId': groupId,
            'classeId': classeId,
            'withHomework': withHomework,
            'color': color
        };
        return axios.post('/calendar-group', params);
    },
    getStudentCalendar(studentId, classeId, withHomework){
        return axios.post('/calendar-student', {
            'studentId': studentId,
            'classeId': classeId,
            'withHomework': withHomework
        });
    },
    saveCategory(category) {
        return axios.post('/calendar-category', category);
    },
}
