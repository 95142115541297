import axios from 'axios';
import entityHelper from '@/api/entityHelper';

export default {

   getConfig2FA(){
    // console.log("GET 2FA CONFIG")
     return axios.get('config/twofactorauth');
   },
  create2FACode(){
    return axios.post('/config/twofactorauth/add2FA');
  },
  active2FA(number){
    let parameters = new FormData();
    parameters.append('2fa_number', number);
     return axios.post('/config/twofactorauth/active2FA',parameters);
  },
  check2FA(code,isTrusted){
    let parameters = new FormData();
    parameters.append('code',code);
    if(isTrusted === true) {
      parameters.append('isTrusted', isTrusted);
    }

    return axios.post('/config/check2FA',parameters);
   },
   disactive2FA(){
     return axios.post('/config/twofactorauth/remove2FA');
   },
   check2FAIsActive(){
    return axios.get('/config/checkActive2FA')
   }
}
