<template>
    <div class="h-100">
        <div class="row h-100">
            <div class="card mx-auto card-block-ie">
                <div :class="['card-body', 'login-card', extraLoginClass]">
                    <div  class="container container-ie">
                        <slot></slot>
                    </div>
                </div>
                <div v-if="loading" class="card-footer footer-card-ie">
                    <div class="spinner-recover-password">
                        <b-spinner small></b-spinner><span class="spinner-text">{{ $t('loading') }}</span>
                    </div>
                </div>
                <div v-else-if="hasMessage" class="cursor-pointer" @click="$emit('clickedMessage')" :class="['card-footer', 'footer-card-ie', 'text-center', messageStatus]">{{$tc(message)}}</div>
            </div>
        </div>
    </div>
</template>
<script>
    import {BSpinner} from 'bootstrap-vue';

    export default {
        name: "card-security",
        props: {
            extraLoginClass: {type: String, default: "", required: false}
        },
        components: {BSpinner},
        data() {
            return{
//                hasMessage: false,
                message: '',
                messageStatus: 'success',
                loading: false,
            }
        },
        methods: {
            setErrors(message, status) {
                this.message = message;
                this.messageStatus = status;
            },
            setLoading(value){
                this.loading = value;
            }
        },
        computed:{
            hasMessage(){
                if(this.$store.state.error){
                    this.message = this.$store.state.error;
                    this.messageStatus = 'bg-danger';
                }
                if(this.message){
                    return true;
                }
                return false;
            }
        }
    }
</script>
<style scoped lang="scss">
    @import '@/assets/css/variables.scss';
    .login-card{
        padding-top: 3rem;
        padding-bottom: 1.5rem;
        padding-left: 0px;
        padding-right: 0px;
        &-footer{
            padding-top: 3rem;
            padding-bottom: 0px;
            padding-left: 0px;
            padding-right: 0px;
    
        }
    }
    .container-ie{
        max-width: 36rem;
    }
    .card{
        width: 500px
    }
    .spinner-recover-password {
        text-align: center;

        .spinner-border {
            color: $brand1;
            width: 20px;
            height: 20px;
        }

        .spinner-text {
            padding-left: 5px;
        }
    }
    .footer-card-ie{
        padding: 25px;
        font-size: 14px;
    }
    .card-block-ie{
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
        padding-top: 25px;
        border: none;
        border-radius: 10px;
    }

    .success{
        background-color: $success;
    }
    .card-footer.footer-card-ie.text-center.success{
        color: $white !important;
        padding: 2rem 0 3rem 2rem;
    }

    @media only screen and (max-width : 450px) {
        .login-card-footer{
            height: 100%;
        }
        .login-card-footer,.login-card{
            width: 100%;
            // height: 100%;
            margin: 0px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            // padding-top: 75px;
            padding-bottom: 0px;
        }
        // .card-block-ie{
        //     // height:90vh !important;
        // }
        .welcome{
            font-size: 26px;
            letter-spacing: 1.3px;
        }
        .moving-fordward{
            letter-spacing: 4.3px;
            font-size: 16px;
        }
    }
    @media only screen and (max-width:768px) and (min-width: 450px){
        .login-card-footer,.login-card{
            margin: 0px;

        }
        .welcome{
            font-size: 30px;
            letter-spacing: 1.6px;
        }
        .moving-fordward{
            font-size: 18px;
            letter-spacing: 6.5px;
        }
        .card{
                max-width: 500px
        }
    }
    @media (max-width: 360px){
        .login-card-footer,.login-card{
            padding-top: 40px;
        }
    }
</style>
