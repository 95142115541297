import axios from 'axios';
import entityHelper from "@/api/entityHelper";

export default {
    getAttendanceStudentsInfo(studentsIds, currentClass) {
        return axios.get("/attendance", {
            params: {
                'studentsIds': studentsIds,
                'date': currentClass.date,
                'timetableSlotId': currentClass.timetableSlot != null ? currentClass.timetableSlot.id : null,
                'groupId': currentClass.group != null ? currentClass.group.id : null,
                'studentId': currentClass.student != null ? currentClass.student.id : null,
                'subjectId': currentClass.subject.id,
                'classeId': currentClass.classeId
            }
        });
    },
    saveAttendanceStudent(currentClass, studentId, typeId) {
        return axios.post("/attendance/student-attend", {
            'currentClass': currentClass,
            'studentId': studentId,
            'typeId': typeId
        });
    },
    removeAttendanceStudent(currentClass, studentId, typeId) {
        return axios.delete("/attendance/student-attend", {
            data: {
                'date': currentClass.date,
                'timetableSlotId': currentClass.timetableSlot.id,
                'groupId': currentClass.group != null ? currentClass.group.id : null,
                'studentId': currentClass.student != null ? currentClass.student.id : studentId,
                'subjectId': currentClass.subject.id,
                'courseStageId': currentClass.courseStage.id,
                'typeId': typeId
            }
        });
    },
    removeAttendancesStudentByIds(attendancesStudentIds) {
        return axios.delete("/attendance/removeMassive", {data: {ids: attendancesStudentIds}});
    },
    applyAttendancesStudents(attendanceTypeId, incidences, force = false) {
        let params = {
            attendanceTypeId: attendanceTypeId,
            incidences: incidences,
            force: force
        };
        return axios.post("/attendance/applyMassive", {params});
    },
    saveAttendanceStudentAndComment(currentClass, studentId, typeId, commentText, commentPublic, commentId) {
        let commentValueId = commentId || 0;

        return axios.post("/attendance/student-attend-comment", {
            'currentClass': currentClass,
            'studentId': studentId,
            'typeId': typeId,
            'commentText': commentText,
            'commentPublic': commentPublic,
            'commentId': commentId
        });
    },
    saveAttendanceComment(currentSession, studentId, typeId, commentText, commentPublic, commentId) {
        let commentValueId = commentId || 0;

        return axios.post("/attendance/student-comment", {
            'currentSession': currentSession,
            'studentId': studentId,
            'typeId': typeId,
            'commentText': commentText,
            'commentPublic': commentPublic,
            'commentId': commentId
        });
    },
    saveMassiveAttendanceStudent(studentIdList, attendanceTypeIdList, currentClass) {
        return axios.post("/attendance/student-attend-massive", {
            'currentClass': currentClass,
            'studentIds': studentIdList,
            'typeIds': attendanceTypeIdList
        });
    },
    removeMassiveAttendanceStudent(studentIdList, attendanceTypeIdList, currentClass) {
        return axios.delete("/attendance/student-attend-massive", {
            data: {
                'currentClass': currentClass,
                'studentIds': studentIdList,
                'typeIds': attendanceTypeIdList
            }
        });
    },
    findAttendanceReport(params) {
        return axios.get('/attendance/report', { params });
    },
    getAttendanceTypes(stageId, joinStage = 0) {
        let stageIdValue = stageId || 0;
        if(joinStage) {
            return axios.get("/attendance/types/" + stageIdValue + '/1');
        }
        return axios.get("/attendance/types/" + stageIdValue);
    },
    removeAttendanceComment(currentSession, studentId, commentId) {
        return axios.delete("/attendance/student-comment", {
            data: {
                'currentSession': currentSession,
                'studentId': studentId,
                'commentId': commentId
            }
        });
    },
    sendAttendanceReport(data) {
        return axios.post('/attendance/send-report', { 'data': data });
    },
    getAttendanceNotificationAutomations() {
        return axios.get("/attendanceconfig", {
            params: {
                'type': 'AttendanceNotification'
            }
        });
    },
    saveAttendanceNotificationAutomation(automation) {
        //if(automation.repetitionDate !== null) automation.repetitionDate =  automation.repetitionDate.format("YYYY-MM-DD");
        //automation.repetitionHour =  automation.repetitionHour.format("HH:mm");
        if(automation.repetitionType && typeof automation.repetitionType == "object" && 'value' in automation.repetitionType){
            automation.repetitionType = automation.repetitionType.value;
        }
        if(automation.value.template && typeof automation.value.template=="object" && 'value' in automation.value.template){
            automation.value.template=automation.value.template.value;
        }
        const automationId = automation.id > 0 ? automation.id : 0;
        return axios.post('/attendance/attendancenotification', {
            automation: automation
        });///' + automationId
    },
    removeAttendanceNotificationAutomation(automation) {
        const automationId = automation.id > 0 ? automation.id : 0;
        return axios.delete("/attendanceconfig/" + automationId);
    },
    getPreviewReport(automationId, startDate, endDate, centerId, format) {
        let centerIdValue = centerId || 0;
        let formatValue = format || "pdf";
        let paramsList = {
            'centerId': centerIdValue,
            'startDate': startDate,
            'endDate': endDate
        };

        return axios.get("/attendanceconfig/preview/" + automationId + "/" + formatValue, { params: paramsList });
    }
}
