import axios from "axios";
export default {
    getToken() {
        return axios.get('/centrifugo/token').then((response) => {
            if (response) {
                return response.data
            }
        }).catch((error) => {
            console.log("ERROR en el getToken --> "+error)
        });
    },
}
