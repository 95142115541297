export default {
    "groupRole.STUDENT": "Alumnat",
    "groupRole.TEACHER": "Professorat",
    "groupRole.COORDINATOR": "Coordinador/es",
    "groupRole.TUTOR": "Tutor/es",
    "groupRole.PAS": "Personal administratiu i de serveis",
    "groupRole.SECRETARY": "Secretari/es",
    "groupRole.DOORKEEPER": "Conserges",
    "groupRole.EAP": "Psicòlegs",
    "groupRole.ADMIN": "Administrador",
    "groupRole.MULTI_ADMIN": "Administrador multi centre",
    "groupRole.SUPER_ADMIN": "Super administrador",
    "groupRole.PARENT": "Familiars i tutors legals",
    "groupRole.CHIEF_DEPARTMENT": "Caps de departament",
    "groupRole.POTENTIALPARENT": "Familiar potencial",
    "groupRole.POTENTIALSTUDENT": "Alumne potencial",
    "groupRole.USER_API": "Usuari API",
    "category.CENTERPROFILE": "Perfil de centre",
    "category.MULTICENTER": "Multicentre",
    "category.IMPORTALL": "Importar tot",
    "category.ROLES": "Permisos",
    "category.PROGRAM": "Programació",
    "category.TUTOR": "Tutor",
    "category.AREAS": "Departaments",
    "category.CONTACTS": "Contactes",
    "category.ACADEMICYEAR": "Any acadèmic",
    "category.ENROLMENT": "Matriculació",
    "category.GROUPS": "Grups",
    "category.SUPERADMIN": "Superadmin",
    "category.IMPORTSPACES": "Importar espais",
    "category.PERSONS": "Persones",
    "category.USERS": "Usuaris",
    "category.SPACES": "Espais",
    "category.GROUPCOURSESTAGE": "GROUPCOURSESTAGE",
    "category.COURSESTAGE": "COURSESTAGE",
    "category.STUDY": "Estudi",
    "category.PARENTS": "Pares",
    "category.CHANGEYEAR": "Permet consultar dades d'altres anys/cursos",
    "category.ADMIN": "Admin",
    "category.MODULES": "mòduls",
    "category.MULTICENTERADMIN": "admin multicentre",
    "category.SUBJECTS": "subjects",
    "category.STAGE": "stage",
    "category.PARAMETER": "parameter",
    "category.ACADEMSTRUC": "academicstruc",
    "category.PROFILE": "Perfil",
    "category.CENTER": "Centre",
    "category.IEDUCA": "Ieduca",
    "category.IMPORT": "Importació",
    "category.ACADEMIC": "Acadèmic",
    'category.CALENDAR': 'Calendari',
    'category.TIMETABLE': 'Horari',
    'category.CLASS': 'Classe',
    'category.GUARD': 'Guàrdia',
    'category.MEETING': 'Reunió',
    "category.ATTENDANCE": "Seguiment d'assistència",
    "category.COMMUNICATIONS": "Comunicats",
    "category.PREREGISTRATION": "Preregistres",
    "category.GRADES": "Notes",
    "category.MESSAGING": "Missatgeria",
    "role.ROLE_CHANGEYEAR": "Canviar d'any (veure/editar dades d'altres anys)",
    "role.ROLE_PROFILE": "Pàgina de 'Perfil'",
    "role.ROLE_PERSONS": "Pàgina 'Persones'",
    "role.ROLE_USERS": "Veure/editar les dades d'usuari en general (per importar, assignar imatges, etc) de totes les persones del centre",
    "role.ROLE_CONTACTS": "Veure/editar els 'Contactes' de la fitxa de la persona",
    "role.ROLE_CONTACTSINFO": "Veure/editar els 'Contactes' a la fitxa de la persona",
    "role.ROLE_PARENTS": "Veure/editar els 'Familiars' de la fitxa de la persona",
    "role.ROLE_GROUPS": "Pàgina 'Grups'",
    "role.ROLE_TAGS": "Veure/editar les 'Etiquetes' de la fitxa de la persona",
    "role.ROLE_2FA": "Autenticació en dos passos",
    "role.ROLE_SPACES": "Pàgina 'Espais' del centre (sales, aules, ...)",
    "role.ROLE_ROLES": "Pàgina 'Permisos'",
    "role.ROLE_IMPORTALL": "Pàgina 'Importar' dades. Per veure una importació determinada cal tenir permisos d'edició de les dades d'aquesta (ex: per importar Persones cal permís d'edició a la secció persones)",
    "role.ROLE_MODULES": "Pàgina 'Mòduls' (actius/inactius)",
    "role.ROLE_AREAS": "Pàgina 'Departaments'",
    "role.ROLE_CENTERPROFILE": "Pàgina de la 'Fitxa del centre'",
    "role.ROLE_COMMENTDEFAULT": "",
    "role.ROLE_COMMENTDEFAULTOWN": "",
    "role.ROLE_PROGRAM": "Pàgina 'Planificació' (temari, objetius, hores...) de la secció 'Alumnat' del menú principal",
    "role.ROLE_ENROLMENT": "Pàgina 'Matricules'",
    "role.ROLE_ACADEMICYEAR": "Pàgina de 'Cursos acadèmics' (anys) de l' Estructura acadèmica'",
    "role.ROLE_PARAMETER": "Pàgina 'Paràmetres' de configuració",
    "role.ROLE_ACADEMSTRUC": "Punt de menú de la secció 'Estructura acadèmica'",
    "role.ROLE_SUBJECTS": "Pàgina 'Matèries' d'Organització docent",
    "role.ROLE_STAGE": "Veure/editar els 'Nivells' o etapes educatives (ESO, BAT, TEENS) de l'estructura acadèmica",
    "role.ROLE_STUDY": "Veure/editar els 'Estudis' de l'estructura acadèmica",
    "role.ROLE_COURSESTAGE": "Veure/editar els de cursos dels nivells (ex: 1r d'ESO)",
    "role.ROLE_GROUPCOURSESTAGE": "Veure/editar els grups oficials (ex: 1r ESO A)",
    "role.ROLE_MULTICENTER": "Gestió multi-centre (de dades compartides enter varis centres)",
    "role.ROLE_CALENDAR": "Pàgina 'Calendari' de l'usuari",
    "role.ROLE_CALENDARS": "Pàgina 'Calendari' del centre",
    "role.ROLE_CALENDARSCONFIG": "Gestió de calendaris",
    "role.ROLE_MANAGETEACHER": "Veure/editar els tutors dels grups oficials",
    "role.ROLE_TEMPLATE": "Pàgina 'Plantilles' (per butlletins, correus, pdfs...)",
    "role.ROLE_TIMETABLE": "Pàgina 'Horari'",
    "role.ROLE_ATTENDANCE": "Pàgina 'Assistència' (Alumnat)",
    "role.ROLE_CLASS": "Gestió de classes fora de l'horari (crear, editar i eliminar)",
    "role.ROLE_CLASSENROLMENTPROGRAM": "Poder afegir alumnes a una classe des de l'apartat 'Classes' (ATENCIÓ: Es matricularà l'alumne a la matèria si la classe és d'un grup oficial)",
    "role.ROLE_CLASSSTUDENTCOMMENT": "Pàgina 'Comentaris' (Alumnat)",
    "role.ROLE_STUDENTCOMMENT": "Veure/editar els 'Comentaris de l'alumne/a' a la fitxa de la persona (fora de classe)",
    "role.ROLE_CLASSSTUDENTS": "Pàgina 'Classe', de la secció Alumnat del menú principal",
    "role.ROLE_CLASSCONTENTS": "Pàgina 'Seguiment' (del continguts, activitats...) (Alumnat)",
    "role.ROLE_GRADES": "Página 'Notes' (Alumnat)",
    "role.ROLE_GRADESCONFIG": "Pàgina 'Configuració > Notes'",
    "role.ROLE_PREREGISTRATION":"Pàgina 'Preinscripcions' (Alumnat)",
    "role.ROLE_PREREGISTRATIONPERSON":"Veure/editar les persones preinscrites",
    "role.ROLE_POTENTIALSTUDENT": "Veure/editar alumnes potencials (necessari per preinscripcions)", // No entenc pq serveix, crec que s'hauria d'esborrar. Revisar quan reprenem el mòdul de preinscripcions!
    "role.ROLE_POTENTIALPARENT": "Veure/editar familiar potencials(necessari per preinscripcions)", // No entenc pq serveix, crec que s'hauria d'esborrar. Revisar quan reprenem el mòdul de preinscripcions!
    "role.ROLE_PERSONBASICINFO": "Veure/editar les 'Dades bàsiques' de la fitxa de la persona",
    "role.ROLE_USERINFO": "Veure/editar les dades d'usuari, a la fitxa de la persona",
    "role.ROLE_USERINFOPERMISSIONS": "Veure/editar els 'Permisos' de la fitxa de la persona",
    "role.ROLE_ENROLMENTINFO": "Veure/editar les dades acadèmiques (informació de la matricula) a la fitxa de la persona",
    "role.ROLE_GRADESINFO": "Veure/editar les notes a la fitxa de la persona",
    "role.ROLE_PERSONCONFIG": "Veure/editar les dades personalitzables (configuració idioma, etc) a la fitxa de la persona",
    "role.ROLE_PERSONFILEADMIN": "Veure/editar arxius a la fitxa de la persona",
    "role.ROLE_PERSONFILEACADEMIC": "Veure/editar fitxers de l'acadèmia",
    "role.ROLE_MESSAGING": "Pàgina 'Missatgeria'",
    "role.ROLE_COMMUNICATIONS": "Pàgina 'Comunicats' del centre",
    "role.ROLE_ALLCONTACTS": "Pàgina 'Contactes' del mòdul de comunicacions",
    "role.ROLE_BILLING": "Veure/editar les dades de la sincronització amb la facturació de 'Key And Cloud'",
    "role.ROLE_BILLINGINVOICES": "Veure/editar les dades de la facturació de 'Key And Cloud' a la persona",
    "role.ROLE_BILLINGINFOKC": "Veure/editar les dades de la sincronització de 'Key And Cloud' a la persona",
    "role.ROLE_CLIENT": "Veure/editar les dades del client",
    "role.ROLE_COMMERCE": "Veure/editar les línies de negoci de l'Estructura acadèmcia (dins Organització docent)",
    "role.ROLE_PERSONS_ROLE": "Veure/editar els permisos dels rols",
    "role.ROLE_COMPETENCE": "Veure/editar competències del centre, a 'Configuració > Notes'",
    "role.ROLE_OWNCOMPETENCE": "Gestionar competències de les meves classes (no visibles per tot el centre)",
    "role.ROLE_COMPETENCEPROG": "Associar competències a una classe",
    "role.ROLE_AMBIT": "Veure/editar els àmbits de les matèries, a 'Configuració > Notes'",
    "role.ROLE_EVALUATIONPERIOD": "Veure/editar els 'Períodes d'avaluació', a 'Configuració > Notes'",
    "role.ROLE_LOGIN": "Permís d'accés a la plataforma",
    "role.ROLE_PARENTGRADES": "Permís perquè els alumnes i familiars vegin les notes",
    "role.ROLE_GRADESREPORT": "Permís per descarregar el butlletí de notes",
    "role.ROLE_ATTENDANCEREPORT": "Generar informes d'assistència",
    "category.KEYANDCLOUD": "KeyAndCloud",
    "role.ROLE_TUTOR": "Veure/editar informació com a tutor",
    "role.ROLE_BILLINGHELPEX": "Veure/editar les dades de la sincronització amb la facturació de 'Helpex'",
    "category.HELPEX": "Helpex",
    "role.ROLE_COMMUNICATIONSSEEALL": "Veure tots els comunicats enviats",
    "role.ROLE_USER": "Permís per a que un usuari pugui veure/editar les seves pròpies dades d'accés",
    "role.ROLE_GROUPSCONFIG": "Veure el punt de menú 'Configuració > Grups'",
    "role.ROLE_ATTENDANCECONFIG": "Veure el punt de menú 'Configuració > Assistència'",
    "role.ROLE_CLASSPLANNINGCONFIG": "Veure el punt de menú 'Configuració > Seguiment/Planificació'",
    "role.ROLE_CLASSDOCUMENTSMENU": "Pàgina 'Documents' (Alumnat)",
    "role.ROLE_CLASSPLANNING": "Pàgina 'Planificació' (Alumnat)",
}
