import axios from 'axios';
import entityHelper from "@/api/entityHelper";

export default {
    getParameters(filters, page, pageSize, orderField, orderMode) {
        return entityHelper.findEntity('/parameters', filters, page, pageSize, orderField, orderMode)
    },
    saveParameter(parameter, value, centerId,stageId) {
        let parameters = new FormData();

        if (typeof(parameter) === 'object') {
            parameters.append('parameterId', parameter.id);
        } else if (typeof(parameter) === 'string') {
            parameters.append('keyName', parameter);
        }

        if (Array.isArray(value)) {  // Si és un iEducaPersonSearch pot enviar N valors, no només un
            parameters.append('value', JSON.stringify(value));
        } else {
            parameters.append('value', value);
        }

        parameters.append('centerId', centerId);
        if(stageId !== undefined && stageId !== null)
          parameters.append('stageId',stageId);
        // console.log('saveParm', parameters);
        return axios.post("/parameters", parameters);
    },
    getParameterValue(key) {
        return axios.get('/parameter/value?key=' + key);
    },
    getParameterValuesStage(keys, stageId) {
        return axios.get('/parameter/values',{
            params: {
                keys: keys,
                stageId: stageId
            }
        });
    },
    getParameterAffectionList() {
        return axios.get('parameter')
    },
    saveParameterCenterAffectation(parameterId, centerId, value,stageId){
        let parameters = new FormData();
        parameters.append('parameterId', parameterId);
        parameters.append('centerId', centerId);
        parameters.append('value', value);
        parameters.append('stageId',stageId);
        return axios.post("/parameterAffectation", parameters);
    },
    saveMultipleParameterCenterAffectations(parameter){
        return axios.post("/parameterAffectations", {'parameter': parameter});
    },
    removeStageAffectation(parameterAffectationId){
      return axios.delete('/parameterAffectation/'+parameterAffectationId);
    }
}
