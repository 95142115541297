export default {
    "param.userForStudent": "Crear usuarios para los alumnos",
    "param.userForParent": "Crear usuarios para los familiares",
    "param.passwordExpire": "Caducidad de la contraseña",
    "param.emailMandatory": "Correo electrónico obligatorio",
    "param.basicCycle": "Educación básica",
    "param.formativeCycle": "Formación profesional",
    "param.academyCycle": "Estudios no reglados",
    "param.calendarMinTime": "Hora de inicio de la agenda",
    "param.calendarMaxTime": "Hora final de la agenda",
    "param.calendarDurationTime": "Minutos de la franja horaria de la agenda",
    "param.timetableWorkingDays": "Dias laborables del centro",
    "param.description.fromMail": "Remitente de los correos electrónicos",
    "param.fromMail": "Remitente de los correos electrónicos",
    "param.inheritsCenter": "Hereda",
    'param.codeOrNameRoom': "Mostrar código (activo) o nombre (inactivo) del aula al imprimir horario",
    'param.codeOrNameGroup': "Mostrar código (activo) o nombre (inactivo) del grupo al imprimir horario",
    'param.codeOrNameSubject': "Mostrar código (activo) o nombre (inactivo) de la materia al imprimir horario",
    'param.allCenterYearTags': "La sincronización con KeyANDCloud genera etiquetas de todos los cursos del centro.",
    "param.nameSurnameOrder": "Formato en el que se guardará el nombre y apellidos de los usuarios en KeyANDCloud.",
    'param.onlyValidatedEmails': 'Enviar correos electrónicos solo a los emails validados',
    'param.calendarHomeWorkCategoryName': 'Nombre de la categoría de los eventos relacionados con poner deberes',
    'param.helpexBlockParentsGradesView': 'Bloquear la vista de las notas de los padres en caso de impago.',
    'param.kcBlockParentsGradesView': 'Bloquear la vista de las notas de los padres en caso de impago.',
    'param.configParentsRecipients': 'Personas o grupos a los que los familiares se pueden dirigir des de Mensajería',
    'param.maximumGradeToEditRecovery': 'Permitir que los profesores puedan poner notas finales en las convocatorias de recuperación solo si la nota de la convocatoria anual es inferior a:',
    "param.blockCompetencesEdit": "Bloquea la edición de las competencias (porcentaje y posición).",
    "param.parentSendTeachersTutors": "Los familiares pueden enviar mensajes a los profesores y tutores de sus hijos.",
    "param.configTokappSubscriber": "Crear automáticamente los usuarios y los grupos nuevos en Tokapp.",
    "param.orderNamesTokapp": "Formato nombre Tokapp",
    "param.parentsGradesViewDetail": "Muestra detalle de las competencias evaluadas de cada materia.",
    "param.ambitOrAreaName": "Nombre del ámbito o departamento en toda la plataforma.",
}
