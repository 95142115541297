<template>
    <SelectIe
            v-if="display"
            ref="yearSelector"
            :class="classCSS"
            name="yearComponent"
            id="yearComponent"
            v-model="defaultOption"
            :options="yearsList"
            :title="yearTitle"
            :labelText="$tc('academicYear')|capitalize"
            :showLabel="showLabel"
            :viewEdit="viewEdit"
            :allowVoid="allowVoid"
            :neverShowErrors="neverShowErrors"
            :searchable="false"
    />
</template>

<script>
    import SelectIe from "./dropdown/SelectIe";
    import { mapState } from 'vuex';
    import stringFunctions from '../utils/stringFunctions';
    import moment from 'moment';

    export default {
        name: "YearComponent",
        components: {
            SelectIe
        },
        model: {
            prop: 'value',
            event: 'year:selected',
        },
        props: {
            value: {type: Object, required: false, default: null},
            displayIfOne: {type: Boolean, required: false, default: false},
            saveToStore: {type: Boolean, required: false, default: true},
            yearTitle: {type: String, required: false, default: function () { return stringFunctions.firstToCapital(this.$tc("activeYear")) }},
            showLabel: {type: Boolean, required: false, default: false},
            viewEdit: {type: Boolean, required: false, default: true},
            allowVoid: {type: Boolean, require: false, default: true},
            neverShowErrors: {type: Boolean, required: false, default: false},
            usedInFilters: {type: Boolean, required: false, default: false},
            yearDefault: {required:false, default:undefined},
            centerFilter: {required:false, default: function(){return [];}},
            showPastYears: {required:false, default: true},
        },
        data() {
            return {
                options: [],
                defaultOption: this.value,
                centerYear: null,
                changeColor: false,
            }
        },
        computed: {
            currentSchema() {
                return this.$store.getters.currentCenter.centerCode;
            },
            display() {
                if (this.options.length > 1 || this.displayIfOne || this.usedInFilters) return true;
                else return false;
            },
            classCSS() {
                let css = ["year-component"];
                if (this.usedInFilters) {
                    css.push("year-filters");
                }
                if (this.changeColor) {
                    css.push("orangeYear");
                }
                return css;
            },
            center() {
                return this.$store.getters.currentCenter.centerCode;
            },
            ...mapState({
                years: state => state.currentCenter.years,
                centerCode: state => state.currentCenter.centerCode,
            }),
            yearsList() {
                let years = [];
                const currentDate = new Date();
                let self = this;
                this.options.forEach(option => {
                    if(!self.showPastYears){
                        const specifiedDate = moment(option.endDate);
                        if(moment(currentDate).isBefore(specifiedDate)){
                            years.push(option)
                        }
                    }
                    else{
                        years.push(option)
                    }
                })

                return years;
            },
        },
        beforeMount() {

        },
        async mounted(){
            this.loadYears();
            this.handleYearsLoaded();
        },
        destroyed() {
            if (this.saveToStore) {
                this.$store.commit('changeYearCode', null);
            }
            if (this.usedInFilters) {
                this.$store.commit("saveLoadedMultiCenterYears", false);
            }
        },
        methods: {
            loadYears(){
                if(this.centerCode){
                    this.$store.dispatch("getYears");
                }
            },
            handleYearsLoaded() {
                let self = this;
                let options = this.years;
                options = self.filterOptions(options);
                if (options !== "pending" && options !== null){
                    self.options = options;
                    if (self.$store.getters.currentCenter.centerCode === "multicenter"){
                        if(!self.$store.getters.currentCenter.loadedMultiCenterYears) {
                            self.defaultOption = self.options[0];
                        }
                    }
                    else{
                        if(!self.yearDefault) {
                            self.defaultOption = {
                                'value': self.$store.getters.currentCenter.yearCode,
                                'label': self.$store.getters.currentCenter.yearName,
                                'payload': self.$store.getters.currentCenter.activeYear
                            };
                        }
                        else{
                            self.getYearByCode(self.yearDefault);
                        }
                    }
                }
                this.options = options;
                this.$emit('yearsLoaded', options);
                if(!self.defaultOption && self.options && self.options.length > 0){
                    self.defaultOption = self.options[0];
                }
            },
            getYearByCode(yearCode){
                let _self = this;
                if(!this.lodash.isNil(yearCode)  && !this.lodash.isNil(this.options) ) {
                    this.options.forEach(item=>{
                        if(item.value === yearCode){
                            _self.defaultOption =  item;
                        }
                    });
                }
            },
            removeSelection(){
                this.$refs.yearSelector.removeSelection();
            },
            filterOptions(years){
                let filteredOptions = [];
                let self = this;
                if(Array.isArray(years)){
                    years.some(function (year) {
                        let add = true;
                        self.centerFilter.some(function (center) {
                            let findCenter = year.centers.find(x => x === center);
                            if (!findCenter){
                                add = false;
                            }
                        });
                        if(add){
                            filteredOptions.push(year);
                        }
                    });
                }
                return filteredOptions;
            },
            seDefaultOption(value){
                this.defaultOption = value;
            }
        },
        watch: {
            yearDefault(newYear) {
                if (typeof newYear === 'object' && newYear != null && "code" in newYear) {
                    this.getYearByCode(newYear.code);
                    this.changeColor = (newYear.code != this.defaultOption.code && !newYear.activeYear);
                }
            },
            defaultOption(newValue, oldValue) {
                let activeYear = this.$store.getters.getActiveYear;
                let schemaName = "";
                let centerCode = this.$store.getters.currentCenter.centerCode;
                if (this.defaultOption !== undefined && this.defaultOption !== null) {
                    if (this.defaultOption.value === null && this.$store.getters.currentCenter.lastYearCode !== null) {
                        this.defaultOption = {
                            'value': this.$store.getters.currentCenter.lastYearCode,
                            'label': this.$store.getters.currentCenter.lastYearName,
                            'payload': this.$store.getters.currentCenter.lastActiveYear,
                        };
                    }
                    if (this.saveToStore) {
                        this.$store.commit('changeYearCode', {
                            'code': this.defaultOption.value,
                            'activeYear': this.defaultOption.payload,
                            'name': this.defaultOption.label
                        });
                    }
                    if (this.display) {
                        if (!this.usedInFilters) {
                            this.$emit('changeYear', this.$store.getters.currentCenter.yearCode);
                        }
                        if (!this.saveToStore) {
                            schemaName = '';
                            if(centerCode && newValue.value){
                                schemaName = centerCode + "_" + newValue.value;
                            }
                            this.$emit('year:selected', {
                                'code': newValue.value,
                                'activeYear': newValue.payload,
                                'name': newValue.label,
                                'validity': newValue.validity,
                                'schemaName': schemaName,
                                'startDate': newValue.startDate,
                                'endDate': newValue.endDate
                            });
                        }
                        if (activeYear) {
                            this.$emit('changeColours', this.defaultOption.value, activeYear.value);
                            this.changeColor = (this.defaultOption?.value !== activeYear?.value && !this.usedInFilters);
                        }
                    }
                } else {
                    if (this.display) {
                        if (newValue === null) {
                            this.$emit('changeColours', activeYear.value, null);
                            this.changeColor = false;
                        }
                        if (!this.usedInFilters) {
                            this.$emit('changeYear', this.$store.getters.currentCenter.yearCode);
                        }
                        if (!this.saveToStore) {
                            if (!this.usedInFilters) {
                                if (activeYear) {
                                    schemaName = centerCode + "_" + activeYear.value;
                                    this.$emit('year:selected',this.defaultOption);
                                }
                            } else {
                                this.$emit('year:selected', {
                                    'code': '',
                                    'activeYear': '',
                                    'name': '',
                                    'schemaName': ''
                                });
                            }
                        }
                    }
                }
            },
            centerCode: {
                handler(newValue) {
                    this.loadYears();
                },
                deep: true,
            },
            options: {
                handler(newValue){
                },
                deep: true
            },
            years:{
                handler(newValue){
                    this.handleYearsLoaded();
                },
                deep: true
            },
            centerFilter: {
                handler(newValue){
                    this.loadYears();
                },
                deep: true
            }
        }

    }
</script>

<style lang="scss">
    @import "@/assets/css/variables.scss";

    .year-component {
        .form-control{
            &.read-only {
                padding-left: 0.75rem !important;
            }
        }
        .v-select{
            &:not(.year-filters){
                background-color: $brand1 !important;
                border-radius: 0.5rem;
                svg { fill: white; }
            }
            .vs__dropdown-toggle .vs__selected-options {
                width: auto;
                min-width: 11rem !important;
            }
            &.enabled { 
                background-color: $brand1 !important;
            }
        }
        .vs__dropdown-toggle .vs__selected-options span.vs__selected { color: white; }
        &.orangeYear .v-select { background-color: $warning; }
    }
    .wrapper-yearComponent{
        display: inline-flex;
        justify-content: flex-end;
        align-items: center;
        .selector-ppk::before{
            border-color: white transparent transparent !important;
            color: white !important;
        }
    }

    .filters-dropdown .year-component {
        margin-top: -0.4rem;
        .vs__dropdown-toggle .vs__selected-options span.vs__selected {
            color: $black !important;
        }
        .v-select {
            border-bottom: 1px solid $brand1 !important;
            background-color: $backColor !important;
            border-radius: 0rem;
            svg { fill: $black !important; }
        }
        &.orangeYear .v-select { border-bottom: 1px solid $warning !important; }
    }
    .wrapper-yearComponent.warning .year-component .v-select,
    .year-component.orangeYear .v-select {
        background-color: $warning !important;
    }
    .wrapper-icon .year-component { margin: -5px 0px 5px 5px; }
    .form-control.read-only {
        padding-left: 0.75rem !important;
    }
</style>
