<template>
    <div :id="id+'-parent'">
        <div :class="'row isdisabled'+disabled">
            <label v-if="showLabelDeff" v-bind:id="'label-'+name" v-bind:for="name" :class="{'with-icon': this.labelIcon !== ''}">
                <i v-if="labelIcon !== ''" :class="('icon ' + labelIcon).trim()"></i>
                {{requiredField && viewEdit ?'*':''}} {{ labelValue }}
                <template v-if="extraInfo">
                    <div class="inline-block" v-b-tooltip.hover :title="extraInfo">
                        <div class="wrapper-icon" v-if="extraInfoIcon !== ''">
                            <div :class="('icon ' + extraInfoIcon).trim()+' tooltip-icon cursor-pointer'"></div>
                        </div>
                    </div>
                </template>
            </label>
            <div class="input-group vertical-center-inside">
                <div :class="iconBorderBottom ? 'border-bottom' : 'wrapper-icon'"  v-if="!showAfterIcon && (icon || showIcon)" >
                    <i v-bind:id="iconName" v-bind:class="icon" @click.stop="iconEvent" @mouseover='mouseover'></i>
                </div>
                <template v-if="viewEdit">
                    <input v-model="inputVal"
                        :tabindex="tabIndexParam"
                        :class="css_class"
                        :id="idComputed"
                        :ref="'input'+idComputed"
                        :type="typeEdited"
                        :name="name"
                        :data-vv-name="name"
                        :placeholder="placeholderDeff"
                        :readonly="disabled"
                        :maxlength="maxlength"
                        :minlength="minlength"
                        @keyup.stop="onKeyUp"
                        @keyup.enter="submit"
                        @keydown="onKeyDown"
                        @change="$emit('change', inputVal, $event)"
                        @blur="onBlur"
                        @focus="focused"
                        @focusout="onFocusOut"
                        @click.stop="clicked"
                    />
                    <i v-if="allowVoid" @click="setInputVal(null)" class="clearable icon icon-close"></i>
                </template>
                <span class="form-control input-lecture" v-else v-html="highlightText(inputVal, textToHighlight)" :id="idComputed" @click.stop="clicked"></span>
                <div :class="iconBorderBottom ? 'border-bottom' : 'wrapper-icon'"  v-if="showAfterIcon && (icon || showIcon)" >
                    <i v-bind:id="iconName" v-bind:class="icon" @click.stop="iconEvent" @mouseover='mouseover'></i>
                </div>
            </div>
            <div v-if="showErrorMsg && !lodash.isEmpty(errorMessage)"
                v-b-tooltip.hover="errorMessage && errorMessage.length > formatMessage(errorMessage, deviceSize).length ? errorMessage : ''"
                :class="[helpMsg ? 'help_msg' : 'error_msg', {'right': helpMsgRight}]"
                :id="'error_' + (id ? id : (new Date()).getTime())">
                {{formatMessage(errorMessage, deviceSize)}}&nbsp;
            </div>
        </div>
        <counter-input
            v-if="counter"
            :min="counterMinValue"
            :max="counterMaxValue"
            :length="inputVal.length"
            class="float-right">
        </counter-input>
    </div>
</template>
<script>
    import {mapState} from "vuex";
    import $ from 'jquery';
    import StringFunctions from '@/utils/stringFunctions';
    import CounterInput from './CounterInput.vue';

    export default {
        components: { CounterInput },
        name: "input-ie",
        model: {
            prop: 'value',
            event: 'input',
        },
        props: {
            name: String,
            label: {type: String, default: ""},
            placeholder: {type: String, required: false},
            type: {type: String, required: true},
            id: {required: false},
            css: String,
            value: null,
            icon: {type: String, required: false, default: null},
            showIcon: {type: Boolean, required: false, default: false},
            viewEdit: {type: Boolean, required: false, default: false},
            showLabel: {type: Boolean, required: false, default: true},
            showErrorMsg: {type: Boolean, required: false, default: true},
            errorMsg: {type: String, required: false},
            labelIcon: {type: String, required: false, default: ""},
            requiredField: {type: Boolean, required: false, default: false},
            tabIndexParam: {required: false, default: 0},
            extraInfo: {type: String, required: false},
            extraInfoIcon: {type: String, required: false},
            textToHighlight: {type: String, required: false},
            alwaysUppercase: {type: Boolean, required: false, default: false},
            showAfterIcon: {type: Boolean, required: false, default: false},
            iconBorderBottom: {type: Boolean, required: false, default: false},
            showFullError: {type: Boolean, required: false, default: false},
            counter: {type: Boolean, required: false, default: false},
            counterMinValue: {type: Number, required: false, default: 0},
            counterMaxValue: {type: Number, required: false, default: 0},
            propErrorStyle: {type: Boolean, required: false, default: false},
            maxlength: {type: Number, required: false, default: 524288},
            minlength: {type: Number, required: false, default: 0},
            allowVoid: {type: Boolean, required: false, default: false},
            helpMsg: {type: Boolean, required: false, default: false},
            helpMsgRight: {type: Boolean, required: false, default: false},
        },
        data() {
            return {
                inputVal: this.value,
                isFocus: false,
                placeholderDeff: this.placeholder,
                errorClass: 'form-control',
                errorDisplay: this.errorMsg,
                showLabelDeff: this.showLabel,
                typeEdited: this.type,
                inputFocused: false,
                highlightInput: false,
                errorStyle: this.propErrorStyle,
            }
        },
        watch: {
            propErrorStyle: function (newValue) {
                this.errorStyle = newValue;
            },
            inputVal: function (newValue) {
                if(this.alwaysUppercase && newValue){
                    this.inputVal = newValue.toUpperCase();
                }
                this.$validator.validate();
                this.$emit('input', this.inputVal);
            },
            value:{
                handler(newValue){
                    this.inputVal = newValue;
                },
                deep: true
            },
            showLabel: {
                handler(newValue){
                    this.showLabelDeff = newValue;
                }
            }
        },
        computed: {
            disabled: function () {
                return !this.viewEdit;
            },
            classObject: function () {
                let cssClass = "";
//            let cssClass = "desocultar";
                if (this.labelIcon !== "") {
                    cssClass += " with-icon";
                }

                return cssClass;
//            if (this.inputVal == "" || this.inputVal == null) {
//                return "ocultar";
//            } else {
//                return "desocultar";
//            }
            },
            css_class: function () {
                let css_class = "form-control";

                if (this.viewEdit) {
                    css_class += " enabled";
                }
                /* if (this.icon !== null) {
                    css_class += " left-padded"; // si te icona, cal aplicar un padding al textfield
                } */
                if (this.inputFocused){
                    css_class += " focused";
                }

                if(this.highlightInput || this.errorMessage){
                    css_class += " border-bottom-red";
                }
                if(this.errorStyle){
                    css_class += " error-style-input";
                }
                return css_class + (this.css ? " " + this.css : "");
            },
            errorMessage: function () {
                if (this.$validator._base.errors.has(this.name)) {
                    return this.$validator._base.errors.first(this.name);
                } else {
                    return this.errorMsg;
                }
            },
            iconName() {
                if (typeof (this.name) === 'undefined') {
                    return "icon-default";
                }
                return "icon-" + this.name;
            },
            labelValue() {
                if (this.label !== "") {
                    return this.label;
                }

                return this.placeholder;
            },
            idComputed(){
                if(this.id){
                    return this.id;
                }
                else{
                    let r = (Math.random() + 1).toString(36).substring(7);
                    return r;
                }
            },
            ...mapState({
                deviceSize: state => state.deviceSize,
            }),
        },
        mounted(){
            this.$emit("mounted");
        },
        methods: {
            setInputVal(val) {
                this.inputVal = val;
            },
            focused: function () {
//          let p = document.getElementById('label-' + this.name);
//          if (p != "undefined" && p != null) {
//            p.setAttribute("class", "desocultar");
//          }
                if (this.placeholder != "") {
                    this.placeholderDeff = '';
                }
                this.inputFocused = true;
            },
            iconFocused: function () {
                let p = document.getElementById('label-' + this.name);
                p.setAttribute("style", "color: var(--btnHover)");
            },
            changeLabelVisibility() {
                if (this.placeholder != "") {
                    this.placeholderDeff = this.placeholder;
                }
//           let p = document.getElementById('label-' + this.name);
//           if (p != "undefined" && p != null) {
//             if (this.inputVal == "" || this.inputVal == null) {
//               p.setAttribute("class", "ocultar");
//             } else {
//               p.setAttribute("class", "desocultar");
//             }
//           }
                // Validacions
                this.errorDisplay = '';
                if (this.$validator._base.errors.has(this.name)) {
                    this.errorDisplay = this.$validator._base.errors.first(this.name);
                }
            },
            iconEvent: function () {
                this.$emit('iconEvent-' + this.name, this.inputVal);
                if ((this.type == "password") && this.viewEdit) {
                    let p = document.getElementsByName(this.name)[0];
                    let eye = document.getElementById('icon-' + this.name);
                    //if (p.getAttribute('type') == 'password') {
                    if (this.typeEdited == "password") {
                        this.typeEdited = "text";
                        p.setAttribute("type", "text");
                        eye.setAttribute("class", "fa fa-eye-slash");
                    } else {
                        this.typeEdited = "password";
                        p.setAttribute("type", "password");
                        eye.setAttribute("class", "fa fa-eye");
                    }
                }
            },
            mouseover: function () {
            },
            focus: function () {
                //console.log("focus, exists?? ",this.$refs['input'+this.idComputed]);
                if(this.$refs['input'+this.idComputed]){
                    this.$refs['input'+this.idComputed].focus();
                }
            },
            clicked: function (event) {
                this.$emit('click', event);
            },
            onBlur: function (event) {
                this.inputFocused = false;
                this.changeLabelVisibility();
                this.$emit('onBlur', event);
            },
            onFocusOut: function (event) {
                this.inputFocused = false;
                this.$emit('onFocusOut', event);
            },
            onClick: function (event) {
                this.$emit('click', event);
            },
            onKeyUp: function (event) {
                //console.log("InputIE onKeyUp: ",event," - id:",this.id," - inputVal: ",this.inputVal, " - value: ",this.value);
                this.changeLabelVisibility();
                this.$emit('keyup', event);
            },
            onKeyDown: function (event) {
                //console.log("InputIE onKeyDown: ",event," - id:",this.id," - inputVal: ",this.inputVal, " - value: ",this.value);
                if(event.keyCode == 9){ // Si fas tab jo necessitava el valor per tant l'afegeixo a l'objecte emitted (per no canviar el k s'enviava fins ara i que res deixo de funcionar)
                    event.value = this.value;
                }
                this.$emit('keydown', event);
            },
            submit: function (event) {
                this.$emit('submit', event);
            },
            formatMessage(message, deviceSize){
                let result = "";
                if(message && !this.showFullError){
                    let widthWrapper = $('#'+this.idComputed).width();
                    let windowWidth = $(window).width();
                    let number = 2;
                    if(windowWidth <= 991){
                        number = 7;
                    }
                    let nChar = message.length;
                    let maxChars = 50;
                    if(nChar*number > widthWrapper){
                        maxChars = widthWrapper/number;
                    }
                    result = message;

                    if(nChar > maxChars && maxChars > 0){
                        let substr = message.substr(0,maxChars);
                        result = substr.length > 0 ? substr+'...' : substr;
                    }
                }
                else if(message && this.showFullError){
                    result = message;
                }
                return result;
            },
            highlightText(text, query){
                if(this.typeEdited == "password"){
                    text = "*".repeat(text.length);
                }
                return StringFunctions.highlightText(text, query);
            },
            highlightInRed(){
                this.highlightInput = true;
            },
            stopHighlight(){
                this.highlightInput = false;
            },
        }
    }
</script>

<style scoped lang="scss">
    @import "../assets/css/variables.scss";

    input {
        border-bottom: 1px solid $linetable;
        &.left-padded { padding-left: 2.4rem !important; }
        &[type="text"], &[type="password"], &[type="email"] { width: 1%; }
    }

    div.input-group-append, .input-group-text {
        border-right: none;
        border-top: none;
    }

    .input-group {
        &:hover div.input-group-append div { border-color: $brand1 }
        &:focus div.input-group-append div { border-color: $brand1 !important; }
        i {
            color: $breadcrumClickable;
            //position: absolute;
            margin: 0.6rem;
            font-size: 1.1rem;
            z-index: 5;
            cursor: pointer;
            &:hover { color: $brand2; }
        }
        .clearable{
            margin: 0;
            position: absolute;
            right: 6px;
            color: rgba(60, 60, 60, 0.5) !important;
        }
    }
    .isdisabledtrue .input-group i { cursor: not-allowed; }
    .input-group-text:hover {
        border-color: $formElementBorderColor !important;
    }
    .form-control:hover {
        box-shadow: none;
        border-color: $brand2;
    }
    i.input + input {
        padding-left: 2rem !important;
    }

    ::placholder {
        color: $placeHolderTextColor !important;
    }
    .error_msg {
//        height: 21px;
        font-size: 0.75rem;
        line-height: 1;
        margin-top: 5px;
    }
    label {
        text-overflow: ellipsis;
        white-space: nowrap;
        &.with-icon {
            padding-left: 30px !important;
            position: relative;
            i.icon {
                font-size: 1.2rem !important;
                position: absolute;
                left: 0;
                top: 2px;
            }
        }
    }

    .stage-titel .form-group{
        .input-group input { background-color: white !important; }
        label { color: $black; }
    }
    .input-with-floppy .input-group input.form-control{
        padding-right: 30px !important;
    }
    .linked .input-lecture:hover{ color:$brand2 !important; }
    .border-bottom {
        border-bottom: 1px solid $borderVueTable !important;
    }
</style>
