import fieldTypeApi from '../../api/Form/fieldType';
import il8n from '../../translations';

export const fieldType = {
    state: {
        fieldTypes: []
    },
    actions: {
        getFieldTypes(context) {
            if (context.state.fieldTypes.length > 0) {
                return context.state.fieldTypes;
            } else {
                /*fieldTypeApi.getTypes().then(response => {
                    if (response.data.status === 'ok') {
                        let defFieldTypes = [];
                        response.data.data.forEach(item => {
                            let key = item.name.split(".");
                            let icon = undefined;
                            switch (key[1]) {
                                case 'checkbox':
                                    icon = 'icon-box-checked pr-1'
                                    break;
                                case'dropdown':
                                    icon = 'icon-arrow-carrot-down-alt2 pr-1'
                                    break;
                                case 'string':
                                    icon = 'icon-align-left  pr-1'
                                    break;
                                case 'grid':
                                    icon = 'icon-table  pr-1';
                                    break;
                                case 'radio':
                                    icon = 'icon-circle-slelected pr-1'; //slelected es correcto
                                    break;
                                case 'timetable':
                                    icon = 'icon-clock pr-1';
                                    break;
                                case 'textarea':
                                    icon = 'icon-align-left pr-1';
                                    break;
                                case 'file':
                                    icon = 'icon-document-alt pr-1';
                                    break;
                            }
                            let option = {
                                value: item.id,
                                label: il8n.tc(item.name),
                                description: il8n.tc(item.description),
                                key: key[1],
                                icon: icon
                            };
                            defFieldTypes.push(option);
                        });
                        context.commit('setFieldTypes', defFieldTypes);
                    }
                });*/
                let input     = { value: 1, label: il8n.tc("fieldType.string.name"),    key: "string",    icon: "icon-minus pr-1"                  };
                let textarea  = { value: 2, label: il8n.tc("fieldType.textarea.name"),  key: "textarea",  icon: "icon-align-left pr-1"             };
                let radio     = { value: 3, label: il8n.tc("fieldType.radio.name"),     key: "radio",     icon: "icon-circle-slelected pr-1"       };
                let checkbox  = { value: 4, label: il8n.tc("fieldType.checkbox.name"),  key: "checkbox",  icon: "icon-box-checked pr-1"            };
                let dropdown  = { value: 5, label: il8n.tc("fieldType.dropdown.name"),  key: "dropdown",  icon: "icon-arrow-carrot-down-alt2 pr-1" };
                let grid      = { value: 6, label: il8n.tc("fieldType.grid.name"),      key: "grid",      icon: "icon-table pr-1"                  };
                let timetable = { value: 7, label: il8n.tc("fieldType.timetable.name"), key: "timetable", icon: "icon-clock pr-1"                  };
                let file      = { value: 8, label: il8n.tc("fieldType.file.name"),      key: "file",      icon: "icon-document-alt pr-1"           };
                
                let defFieldTypes = [input, textarea, radio, checkbox, dropdown, grid, timetable, file];
                
                context.commit('setFieldTypes', defFieldTypes);
            }        
        }
    },
    mutations: {
        setFieldTypes(state, filedTypes) {
            state.fieldTypes = filedTypes;
        }
    }
}
