export default {
    "param.userForStudent": "Crear usuaris per l'alumnat automàticament",
    "param.userForParent": "Crear usuaris pels pares automàticament",
    "param.passwordExpire": "Caducitat contrasenya",
    "param.emailMandatory": "Correu electrònic obligatori.",
    "param.basicCycle": "Ensenyança obligatòria",
    "param.formativeCycle": "Formació professional",
    "param.academyCycle": "Estudis no reglats",
    "param.calendarMinTime": "Hora inici de l'horari l'agenda",
    "param.calendarMaxTime": "Hora d'acabament de l'horari l'agenda",
    "param.calendarDurationTime": "Mida de les franges de l'agenda",
    "param.timetableWorkingDays": "Dies laborables del centre",
    "param.description.fromMail": "Remitent dels correus electrònics ",
    "param.fromMail": "Remitent correus electrònics ",
    "param.inheritsCenter": "Hereda",
    'param.codeOrNameRoom': "Mostra codi (actiu) o nom (inactiu) de l'aula a l'imprimir horari",
    'param.codeOrNameGroup': "Mostra codi (actiu) o nom (inactiu) del grup a l'imprimir horari",
    'param.codeOrNameSubject': "Mostra codi (actiu) o nom (inactiu) de la matèria a l'imprimir horari",
    'param.allCenterYearTags': "La sincronització amb KeyANDCloud genera etiquetes de tots els cursos del centre.",
    "param.nameSurnameOrder": "Format en el que es guardarà el nom i cognoms dels usuaris a KeyANDCloud.",
    'param.onlyValidatedEmails': 'Enviar correus electrònics només als emails validats',
    'param.calendarHomeWorkCategoryName': 'Nom de la categoria dels esdeveniments relacionats amb posar deures.',
    'param.helpexBlockParentsGradesView': 'Bloquejar la vista de les notes dels pares en cas d\'impagament.',
    'param.kcBlockParentsGradesView': 'Bloquejar la vista de notes dels pares en cas d\'impagament.',
    'param.configParentsRecipients': 'Persones o grups als que familiars es poden dirigir des de Missatgeria',
    'param.maximumGradeToEditRecovery': 'Permetre que els professors puguin posar notes finals a les convocatòries de recuperació només si la nota de la convocatòria anual és inferior a:',
    "param.blockCompetencesEdit": "Bloqueja l'edició de les competències (percentatge i posició).",
    "param.parentSendTeachersTutors": "Els familiars poden enviar missatges als professors i tutors dels seus fills.",
    "param.configTokappSubscriber": "Crear automàticament els usuaris i els grups nous a Tokapp.",
    "param.orderNamesTokapp": "Format nom Tokapp",
    "param.parentsGradesViewDetail": "Mostra detall de les competències avaluades de cada matèria.",
    "param.ambitOrAreaName": "Nom de l'àmbit o departament en tota la plataforma.",
}
