import ProgramApi from '@/api/core/program';
import lodash from 'lodash'
import moment from 'moment';
import Vue from 'vue';

const initialState = () => ({
    programs: {},
});

export const program = {
    state: initialState(),
    mutations: {
        setProgram(state, params){
            Vue.set(state.programs, params.id, params.program);
        },
        setPrograms(state, programs){
            state.programs = programs;
        }
    },
    getters: {
        getPrograms: state => {
            return state.programs;
        }
    },
    actions: {
        async getProgram(context, params){
            let force = false;
            // console.log("getProgramStore", params);
            if(typeof params != "undefined" && 'force' in params){   force = params.force;  }
            if(!force && context.state.programs[params.programId] && context.state.programs[params.programId] !== "pending"){
                return context.state.programs[params.programId];
            } else if(force || (context.state.programs[params.programId] !== "pending" && params.programId)){
                context.commit("setProgram", {id: params.programId, program: "pending"});
                let year = undefined;
                if(params.year && !lodash.isNil(params.year)){
                    year = params.year
                }
                await ProgramApi.getProgram(params.programId, year).then((response) => {
                    if (response && response.data.status == "ok") {
                        context.commit("setProgram", {id: params.programId, program: response.data.program});
                        return response.data.program;
                    }
                }).catch(e => {
                    context.commit("setProgram", {programId: params.programId, program: null});
                });
            }
        },
    }
}

