export default {
    "groupRole.STUDENT": "Alumnado",
    "groupRole.TEACHER": "Profesorado",
    "groupRole.COORDINATOR": "Coordinador",
    "groupRole.TUTOR": "Tutor",
    "groupRole.PAS": "Personal administrativo y de servicios",
    "groupRole.SECRETARY": "Secretariado",
    "groupRole.DOORKEEPER": "Conserges",
    "groupRole.EAP": "Psicólogos",
    "groupRole.ADMIN": "Administradores",
    "groupRole.MULTI_ADMIN": "Administrador multi centro",
    "groupRole.SUPER_ADMIN": "Super administrador",
    "groupRole.PARENT": "Familiares y tutores legales",
    "groupRole.CHIEF_DEPARTMENT": "Responsables de departamento",
    "groupRole.POTENTIALPARENT": "Familiar potencial",
    "groupRole.POTENTIALSTUDENT": "Alumno potencial",
    "groupRole.USER_API": "Usuario API",
    "category.CENTERPROFILE": "Perfil de centro",
    "category.MULTICENTER": "Multicentro",
    "category.IMPORTALL": "Importar todo",
    "category.ROLES": "Permisos",
    "category.PROGRAM": "Programación",
    "category.TUTOR": "Tutor",
    "category.AREAS": "Departamentos",
    "category.CONTACTS": "Contactos",
    "category.ACADEMICYEAR": "Año académico",
    "category.ENROLMENT": "Matriculación",
    "category.GROUPS": "Grupos",
    "category.SUPERADMIN": "Superadmin",
    "category.IMPORTSPACES": "Importar espacios",
    "category.PERSONS": "Personas",
    "category.USERS": "Usuarios",
    "category.SPACES": "Espacios",
    "category.GROUPCOURSESTAGE": "GROUPCOURSESTAGE",
    "category.COURSESTAGE": "COURSESTAGE",
    "category.STUDY": "Estudio",
    "category.PARENTS": "Padres",
    "category.CHANGEYEAR": "Cambio año",
    "category.ADMIN": "Admin",
    "category.MODULES": "módulos",
    "category.MULTICENTERADMIN": "admin multicentro",
    "category.SUBJECTS": "subjects",
    "category.STAGE": "stage",
    "category.PARAMETER": "parameter",
    "category.ACADEMSTRUC": "academicstruc",
    "category.PROFILE": "Perfil",
    "category.CENTER": "Centro",
    "category.IEDUCA": "Ieduca",
    "category.IMPORT": "Importación",
    "category.ACADEMIC": "Académico",
    'category.CALENDAR': 'Calendario',
    'category.TIMETABLE': 'Horario',
    'category.CLASS': 'Clase',
    'category.GUARD': 'Guardia',
    'category.MEETING': 'Reunión',
    "category.ATTENDANCE": "Seguimiento de asistencia",
    "category.COMMUNICATIONS": "Comunicados",
    "category.PREREGISTRATION": "Pre-registros",
    "category.GRADES": "Notas",
    "category.MESSAGING": "Mensajería",
    "role.ROLE_CHANGEYEAR": "Cambiar de año (consultar/editar datos de otros años)",
    "role.ROLE_PROFILE": "Página de 'Perfil'",
    "role.ROLE_PERSONS": "Página 'Personas'",
    "role.ROLE_USERS": "Ver/editar usuarios (importar, asignar imagen...)",
    "role.ROLE_CONTACTS": "Ver/editar los contactos de la ficha de la persona",
    "role.ROLE_PARENTS": "Ver/editar los familiares de la ficha de la persona",
    "role.ROLE_GROUPS": "Página 'Grupos'",
    "role.ROLE_TAGS": "Ver/editar las etiquetas de la ficha de la persona",
    "role.ROLE_2FA": "Autenticación en dos pasos",
    "role.ROLE_SPACES": "Página 'Espacios' del centro (aulas, salas...)",
    "role.ROLE_ROLES": "Página 'Permisos'",
    "role.ROLE_IMPORTALL": "Página 'Importar' datos",
    "role.ROLE_MODULES": "Página 'Módulos' (activos/inactivos)",
    "role.ROLE_AREAS": "Página 'Departamentos'",
    "role.ROLE_CENTERPROFILE": "Página de la 'Ficha del centro'",
    "role.ROLE_COMMENTDEFAULT": "Comentarios de notas predefinidos (del usuario)",
    "role.ROLE_COMMENTDEFAULTOWN": "Comentarios de notas predefinidos (del centro)",
    "role.ROLE_PROGRAM": "Página 'Planificación' de contenidos (temario, objetivos, horas...) de la sección 'Alumnado' del menú principal",
    "role.ROLE_ENROLMENT": "Página 'Matriculas' de la sección 'Alumnado' del menú principal",
    "role.ROLE_ACADEMICYEAR": "Pàgina 'Cursos académicos' (años) de la 'Estructura académica'",
    "role.ROLE_PARAMETER": "Página 'Parámetros' de configuración",
    "role.ROLE_ACADEMSTRUC": "Punto de menú de la 'Organización docente'",
    "role.ROLE_SUBJECTS": "Página 'Materias' de la 'Organización docente'",
    "role.ROLE_STAGE": " Ver/editar los Niveles / etapas educativas (ex: ESO, BAT, TEENS) de la 'Estructura académica' ",
    "role.ROLE_STUDY": " Ver/editar los estudios de la 'Estructura académica' ",
    "role.ROLE_COURSESTAGE": "Ver/editar los cursos de las etapas educativas (ex: 1r ESO)",
    "role.ROLE_GROUPCOURSESTAGE": "Ver/editar los grupos oficiales (ex: 1ESOA)",
    "role.ROLE_MULTICENTER": "Gestión multi-centro (datos compartidos entre varios centros)",
    "role.ROLE_CALENDAR": "Página 'Calendario' del usuario",
    "role.ROLE_CALENDARS": "Página 'Calendario' del centro (festivos, etc)",
    "role.ROLE_CALENDARSCONFIG": "Gestión de calendarios",
    "role.ROLE_MANAGETEACHER": "Ver/editar los tutores de los grupos oficicales",
    "role.ROLE_TEMPLATE": "Página 'Plantillas' (para boletas, pdfs...)",
    "role.ROLE_TIMETABLE": "Página 'Horario'",
    "role.ROLE_ATTENDANCE": "Página 'Asistencia' (Alumnado)",
    "role.ROLE_CLASS": "Gestión de clases fuera del horario (crear, editar y eliminar)",
    "role.ROLE_CLASSENROLMENTPROGRAM": "Poder añadir alumnos a una clase des del apartado Clases (ATENCIÓN: Se matriculará al alumno a la materia de la clase si es un grupo oficial)",
    "role.ROLE_CLASSSTUDENTCOMMENT": "Página 'Comentarios' (Alumnado)",
    "role.ROLE_STUDENTCOMMENT": "Ver/editar los 'Comentarios del alumno/a' en la ficha de la persona (fuera de clase)",
    "role.ROLE_CLASSSTUDENTS": "Página 'Clase', del menú de Alumnado",
    "role.ROLE_CLASSCONTENTS": "Página 'Seguimiento' de los contenidos (Alumnado)",
    "role.ROLE_GRADES": "Página 'Notas' (Alumnado)",
    "role.ROLE_GRADESCONFIG": "Página 'Configuración > Notas'",
    "role.ROLE_PREREGISTRATION": "Página 'Preinscripciones' (Alumnado)",
    "role.ROLE_PREREGISTRATIONPERSON": "Ver/editar las personas preinscritas",
    "role.ROLE_POTENTIALSTUDENT": "Ver/editar alumnos potenciales (necessario para preinscripciones)", // No entenc pq serveix, crec que s'hauria d'esborrar. Revisar quan reprenem el mòdul de preinscripcions!
    "role.ROLE_POTENTIALPARENT": "Ver/editar familiares potenciales (necessario para preinscripciones)", // No entenc pq serveix, crec que s'hauria d'esborrar. Revisar quan reprenem el mòdul de preinscripcions!
    "role.ROLE_PERSONBASICINFO": "Ver/editar los 'Datos básicos' en la ficha de la persona",
    "role.ROLE_CONTACTSINFO": "Ver/editar los 'Contactos' en la ficha de la persona",
    "role.ROLE_USERINFO": "Ver/editar la seccion de datos de 'Usuario' en la ficha de la persona",
    "role.ROLE_USERINFOPERMISSIONS": "Ver/editar los 'Permisos' de la ficha de la persona",
    "role.ROLE_ENROLMENTINFO": "Ver/editar los 'Datos académicos' (información de la matricula) en la ficha de la persona",
    "role.ROLE_GRADESINFO": "Ver/editar las notas en la ficha de la persona",
    "role.ROLE_PERSONCONFIG": "Ver/editar los datos personalizables (configurar idioma, etc) en la ficha de la persona",
    "role.ROLE_PERSONFILEADMIN": "Ver/editar archivos en la ficha de la persona",
    "role.ROLE_PERSONFILEACADEMIC": "Ver/editar archivos de la academia",
    "role.ROLE_MESSAGING": "Página 'Mensajes' (mensajería interna de la persona)",
    "role.ROLE_COMMUNICATIONS": "Página 'Comunicados' del centro",
    "role.ROLE_ALLCONTACTS": "Página 'Contactos' del módulo de comunicaciones",
    "role.ROLE_BILLING": "Ver/editar los datos de la sincronización con la facturación de 'Key And Cloud'",
    "role.ROLE_BILLINGINVOICES": "Ver/editar los datos de la facturación de 'Key And Cloud' en la persona",
    "role.ROLE_BILLINGINFOKC": "Ver/editar los datos de la sincronización de 'Key And Cloud' en la persona",
    "role.ROLE_CLIENT": "Ver/editar los datos del cliente",
    "role.ROLE_COMMERCE": "Ver/editar las líneas de negocio de la estructura académica (en Organización docente)",
    "role.ROLE_PERSONS_ROLE": "Ver/editar los permisos de los roles",
    "role.ROLE_COMPETENCE": "Ver/editar competencias del centro, en la 'Configuración > Notas'",
    "role.ROLE_OWNCOMPETENCE": "Ver/editar competencias de mis clases",
    "role.ROLE_COMPETENCEPROG": "Asociar competencias a una clase",
    "role.ROLE_AMBIT": "Ver/editar los ámbitos de las materias, en la 'Configuración > Notas'",
    "role.ROLE_EVALUATIONPERIOD": "Ver/editar los períodos de evaluación, en 'Configuración > Notas'",
    "role.ROLE_LOGIN": "Permiso de acceso a la plataforma",
    "role.ROLE_PARENTGRADES": "Permiso para que los alumnos y familiares puedan ver las notas",
    "role.ROLE_GRADESREPORT": "Permiso para descargar el boletín de notas",
    "role.ROLE_ATTENDANCEREPORT": "Generar informes de asistencia",
    "category.KEYANDCLOUD": "KeyAndCloud",
    "role.ROLE_TUTOR": "Ver/Editar información como tutor",
    "category.HELPEX": "Helpex",
    "role.ROLE_BILLINGHELPEX": "Ver/editar los datos de la sincronización con la facturación de 'Helpex'",
    "role.ROLE_COMMUNICATIONSSEEALL": "Ver todos los comunicados enviados",
    "role.ROLE_USER": "Permiso para que un usuario pueda ver/editar sus propios datos de acceso",
    "role.ROLE_GROUPSCONFIG": "Ver el punto de menú 'Configuración > Grupos'",
    "role.ROLE_ATTENDANCECONFIG": "Ver el punto de menú 'Configuración > Asistencia'",
    "role.ROLE_CLASSPLANNINGCONFIG": "Ver el punto de menú 'Configuración > Seguimiento/Planificación'",
    "role.ROLE_CLASSDOCUMENTSMENU": "Página 'Documentos' (Alumnado)",
    "role.ROLE_CLASSPLANNING": "Página 'Planificació' (Alumnado)",
}
