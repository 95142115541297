import axios from 'axios';
import entityHelper from '@/api/entityHelper'

export default {
    getFunctionalityTemplates(filters="", page="", pageSize="", orderField="", orderMode=""){
        // return axios.get('functionalityTemplates')
        return entityHelper.findEntity("/functionalityTemplates", filters, page, pageSize, orderField, orderMode);
    },
    getFunctionalityPrintTags(functionalityId){
        return axios.get('/templates/functionality/'+functionalityId+'/printtags');
    },
    getTemplate(templateId){
        return axios.get('/template/'+templateId)
    },
    getFunctionalities(moduleId) {
        let config = {};
        config['params'] = { 'moduleId': moduleId };
        return axios.get('/functionalities', config);
    },
    getPreviewPDF(content, horizontal){
        return axios.post('/templates/previewPDF',{
            params:{
                content: content,
                horizontal: horizontal,
            },
        },{
            responseType: 'arraybuffer'
        });
    },
    getPreviewPDFByTemplateId(templateId){
        return axios.post('/templates/previewPDFById/'+templateId, {},{
            responseType: 'arraybuffer'
        });
    },
    saveFullTemplate(template, functionality, functionalityTemplate,affections,templateCourseStages){
        return entityHelper.saveEntity({template, functionality, functionalityTemplate,affections,templateCourseStages}, '/templateFull');
    },
    deleteFunctionalityTemplate(functionalityTemplateId){
        return axios.delete('/functionalityTemplate/'+functionalityTemplateId);
    },
    saveFunctionalityTemplate(functionalityId, templateId){
        return axios.post('/functionality/'+functionalityId+'/template/'+templateId);
    },
    getTemplatesOfFunctionalityByCode(functionalityCode, courseStageId = null){
        let params = { functionalityCode: functionalityCode, courseStageId: courseStageId };
        return axios.get('/functionality/templates', {params});
    },
    saveTemplateCourseStage(templateId, functionalityId, templateCourseStages){
        let params = {
            templateId: templateId,
            functionalityId: functionalityId,
            templateCourseStages: templateCourseStages
        };
        return axios.post('/templateCourseStages', {params});
    },
    getPreviewImageByTemplateId(templateId){
        return axios.get('/templates/previewImageById/'+templateId,{responseType: 'arraybuffer'}).then(
            (response) => {
                if(response.data!="" && response.data!="undefined" && response.data.byteLength>0){
                    var bytes = new Uint8Array(response.data); // get info de la imatge que hem serveix el backend
                    var binary = bytes.reduce((data, b) => data += String.fromCharCode(b), ''); // bytes latin
                    return "data:image/jpeg;base64," + btoa(binary); // base62
                }else {
                    return "";
                }
            }
        );
    },
    getFunctionalityTemplatesForPrinting(moduleId, courseStageId){
        let params = { moduleId: moduleId, courseStageId: courseStageId };
        return axios.get('/functionalityTemplatesForPrinting', {params});
    },
    getTemplatesForGroups(){
        return axios.get('/templates/forGroups');
    },
    generateTemplateForGroup(params){
        return axios.post('/template/'+params.templateId+'/group/'+params.groupId+'/schema/'+params.schemaName+'/generate',{},{
            responseType: 'arraybuffer'
        });
    },
    getTemplateHtmlByKeyName(keyName, courseStageId) {
        return axios.post('/templateByKeyName/' + keyName + (courseStageId > 0 ? '/' + courseStageId : ""));
    }
}
