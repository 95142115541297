import entityHelper from '@/api/entityHelper';
import axios from "axios";

export default {
    findCourseStages(filters, page, pageSize, sortField, sortOrder) {
        return entityHelper.findEntity("/course-stage", filters, page, pageSize, sortField, sortOrder);
    },
    getStageCourseStages(viewAll, teacherId = null) {
        return axios.get("/coursestage-stage", {
            params: {
                'teacherId': teacherId,
                'viewAll': viewAll
            }
        });
    },
    getCourseStagesOfStudent(studentId) {
        let params = {studentId: studentId};
        return axios.get("/coursestages/getCourseStagesOfStudent", {params});
    },
    hasRepetitionEvents(courseStageId){
        return axios.get("/coursestage/"+courseStageId+"/hasRepetitionEvents");
    },
    getCourseStagesSchema(schema, stageId) {
        return axios.get(`/coursestages-schema/${schema}/${stageId}`);
    }
}
