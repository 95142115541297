export default {

  defaultMax: 10,
  cutCompleteName(nom, secondName, thirdName, maxChar, nameLast = false) {
        let nameToReturn;
      nom = this.nameCleaner(nom);
      secondName = this.nameCleaner(secondName);
      if(thirdName === null){
        thirdName = '';
      }
      thirdName = this.nameCleaner(thirdName);

      if (isNaN(maxChar) || maxChar === undefined) {
        maxChar = this.defaultMax;
      }
      let maxName = Math.round(maxChar / 3, 0);
      let maxSurnames = Math.round(maxName * 2);
      let half = Math.round(maxChar / 2);

      if((secondName+thirdName).length == 0){
        return this.cutName(nom,maxChar);
      }

      if ((nom + ' ' + secondName + ' ' + thirdName).length >= maxChar) {
        if (secondName.length > half && thirdName.length > half && nom.length < half) {
          return this.cutName(nom, maxName) + ' ' + this.cutSurnames(secondName, thirdName, maxSurnames);
        } else if (secondName.length < half && nom.length > half && thirdName.length > half) {
          return this.cutName(nom, maxName) + ' ' + this.cutSurnames(secondName, thirdName, 2)
        } else if ((secondName + thirdName).length < half && nom.length > half) {
          return this.cutName(nom, maxName) + ' ' + secondName + ' ' + thirdName;
        } else {
          return this.cutName(nom, maxName) + ' ' + this.cutSurnames(secondName, thirdName, maxSurnames);
        }
      } else {
          if(nameLast){
              nameToReturn = (secondName ? secondName : '') + ' ' + (thirdName ? thirdName : '') + ', ' + (nom ? nom : '');
          }else{
              nameToReturn = (nom ? nom : '')+ ' '  + (secondName ? secondName : '') + ' ' + (thirdName ? thirdName : '');
          }
          if ((secondName + thirdName).length > 0 && nom.length > 0) {
              // return secondName + ' ' + thirdName + ', ' + nom;
              return nameToReturn;
          } else {
              // return (secondName + ' ' + thirdName + ' ' + nom).trim();
              return nameToReturn.trim();
          }
      }
    },
    nameCleaner(nom) {
      if (nom === undefined || nom == null) {
        return '';
      }
      return nom.trim();
    },
    cutSurnames(secondName, thirdName, maxLenght) {
      if(thirdName === null){
        thirdName = '';
      }
      let result = '';
      if ((secondName + thirdName).length+1 >= maxLenght) {
        if (secondName.length > maxLenght && secondName.length > thirdName.length) {
          let splited = secondName.split(" ");
          if (splited.length > 1) {
            result = splited[0] + ' ' + splited[1].substr(0, 1)+'.';
          } else {
            result = secondName.substr(0, maxLenght - 1);
          }
        } else {
          if(thirdName.length > 0){
            result = secondName + " " + thirdName.substr(0, 1)+'.';
          }
          else{
            result = secondName;
          }
        }
      }else{
        result = secondName+ ' '+ thirdName;
      }
      return result;
    },
    cutName(name, maxLenght,initials) {
      if(initials === undefined || initials === null) initials=true;
      name = this.nameCleaner(name);
      let result = name;
      if (name.length > maxLenght) {
        let splited = name.split(" ");
        if (splited.length > 1) {
          if ((splited[0] + splited[1]).length + 1 < maxLenght) {
            if(initials) {
              result = splited[0] + " " + splited[1] + ' ' + splited[2].substr(0, 1) + ".";
            }else{
              result = splited[0] + " " + splited[1];
            }
          } else {
            if(initials){
              result = splited[0] + " " + splited[1].substr(0, 1) + ".";
            }else {
              result = splited[0];
            }
          }
        }else{
          result = name.substr(0,maxLenght-4)+'...';
        }
      }
      return result;
    }

}

