<template>
    <span @click="click" class="alert-icon">
        <i :class="icon"></i>
        <span v-if="number && number>0" :class="'icon-them '+extraCss">
            <label :class="'text-center '+extraCss">{{number}}</label>
        </span>
    </span>
</template>
<script>
    export default {
        name: "alert-icon",
        props:{
            icon: { type: String, required: true },
            number: { type: Number, required: false },
            extraCss: { type: String, required: false },
        },
       methods:{
            click(){
                this.$emit("click");
            }
        }
    }
</script>
<style scope lang="scss">
    @import "../assets/css/variables.scss";
    @import url("../assets/css/ieduca_font.css");
    .rounded-circle-ie{
        padding: 2px;
        font-size: 1.25rem;
        color: $error;
        &:before{
            content:"\e1a8";
            font-family: "ieduca" !important;
            color:red;
        }
    }
    .alert-icon{
        font-size: 1.5rem;
        display: inline-flex;
    }
    .icon-them{
        font-size: 0.7rem;
        margin-left: -0.8rem;
        margin-top: -0.25rem;
        background-color: $error;
        border-radius:50%;
        height: 1.1rem;
        width: 1.30rem;
        label{
            color:#ffffff;
            padding: 0px;
            margin: 0px;
            line-height: initial;
        }
        &.cursorPointer:hover{
            background-color: orangered;
        }
    }
    @media (max-width: 768px){
        .icon-them{
            font-size: 0.65rem;
            margin-left: -0.7rem;
            margin-top: -0.35rem;
            height: 1rem;
            width: 1.1rem;
            label {
                margin-top: 1px;
            }
        }
    }
 </style>
