import axios from 'axios'
import router from '../router'
import securityHelper from '../utils/securityHelper'
import store from '../store/store'
import authApi from '../api/core/auth'
import Vue from 'vue';
import GlobalMixin from '../mixins/global';
import i18n from '../translations/index';
Vue.mixin(GlobalMixin);


// mirar wike  Fulxe authentication front.
export default {
    errorInterceptorResponse (error) {
        //console.log('---> errorInterceptorResponse: ', error)
        if (axios.isCancel(error)) { // si s'ha cancelat no s'ha de gestionar
            //console.log('-- CANCELAT, fem return')
            return
        }
        if (!error.response) {
            //console.log('Please check your internet connection?? fem return? és k sinó petarà a la linia seguent... posem  ')
            if (error !== undefined && error !== null) return Promise.reject(error)
            //return Promise.reject(error);
            return //- si la linia de sobre falla, provar aquesta
        }
        const { config, response: { status } } = error
        const originalRequest = config
        if (status === 401) { // token caducat
            //console.log("before managerRefreshToken");
            securityHelper.managerRefreshToken()
            const retryOriginalRequest = new Promise((resolve) => {
                securityHelper.addSubscriber(access_token => {
                    originalRequest.headers.Authorization = 'Bearer ' + access_token.data.access_token
                    resolve(axios(originalRequest))
                })
            })
            return retryOriginalRequest
        } else if (status === 455) {
            if (error.response.data.error === 'user.No.trustedDevice') {
                securityHelper.setToken(error.response.data.access_token, error.response.data.refresh_token, false)
            }
            router.push({ name: error.response.data.redirect, params: { error: error.response.data.error } }).catch(e => { console.log("Error route name: '" + error.response.data.redirect + "'") });
            //Si el bé de login tornem el response ja que es necessita agafar les variables.
            let baseUrl = error.config.baseURL
            let url = error.config.url
            url = url.replace(baseUrl, '')
            if (url === '/oauth/v2/token') {
                return error.response
            }
            // if (url === '/token') {
            //     return error.response
            // }
            return
        } else if (status === 403) {
            let vueInstance = new Vue({ i18n });
            vueInstance.toast(vueInstance.$tc('error.some403'), 'Error', 'error');
        } else if (status === 500 && error.response.data.error_code === 4100) {
            //console.log('error status 500 and error_code 4100')
            router.push({ name: 'Logout' }).catch(e => { console.log("Error route name: 'Logout'") });
            if (error !== undefined && error !== null) return Promise.reject(error)
            return
        }
        return Promise.reject(error)
    },
    onFulfilledRequest (config) {
        //console.log('Inici del request')
        const skipUrls = ['/login', '/oauth/v2/token', '/token', '/change/password', 'signup', '/clients', '/clientImage']
        const anonymousUrls = ['/userLang', '/preregistration/Api', '/country', '/country-province/',
            '/country-town/', '/province', '/province-town', '/town-country/', '/postal-code/', '/postalcode-info/'
            , '/preregistration/Api/SignUp', '/changePassword', '/recoverPassword', '/changePassword/validate'
        ] // request que només necessiten un token anònim.

        let urlArray = config.url.split('?')
        let urlToCheck = urlArray[0]
        // Direccions que no necessiten cap token no cal controlar el token
        if (skipUrls.includes(urlToCheck)) {
            //console.log('Url sensa token: ' + urlToCheck)
            return config
        }

        const originalRequest = config
        let token = sessionStorage.token
        let refresh = localStorage.refresh
        let anonymous = (localStorage.anonymous == 'true')
        let isLoggedIn = localStorage.isLoggedIn;
        let date = new Date()
        let isExpireTime = (parseInt(localStorage.expireTime) < date.getTime())
        //console.log('variables onFullFilledRequest token:' + token + ' refreshToken:' + refresh + ' anonymous:' + anonymous + ' isExpireTime:' + isExpireTime)
        //mirem si cal demanar token pq no en tenim o està caducat
        if (typeof token === 'undefined' || isExpireTime) {
            //console.log('token null o caducat  token:' + token + ' isExpireTime:' + isExpireTime)
            if (!isLoggedIn && (anonymousUrls.includes(urlToCheck) || skipUrls.includes(urlToCheck))) {
                //console.log('Es demana un token anònim ja que no tenim token de refresc i la direcció es a la part pública: ' + urlToCheck)
                if (!securityHelper.isAlreadyFetchingAccessToken) {
                    //console.log('Demanem token anònim')
                    securityHelper.isAlreadyFetchingAccessToken = true
                    authApi.requestAnonimusToken().then((access_token) => {
                        //console.log('resposta del token anònim', urlToCheck);
                        securityHelper.isAlreadyFetchingAccessToken = false
                        securityHelper.setToken(access_token.data.access_token, undefined, true, access_token.data.expires_in)
                        securityHelper.onAccessTokenFetchedAnonymous(access_token)
                    })
                }
                //promesa que quan acabi tindrà un access_token anònim
                const retryOriginalRequest = new Promise((resolve) => {
                    //console.log('creem promesa token anònim per :' + urlToCheck)
                    securityHelper.addSubscriberAnonymous(access_token => {
                        originalRequest.headers.Authorization = 'Bearer ' + access_token.data.access_token
                        resolve(originalRequest)
                    })
                })
                //retornem promesa
                return retryOriginalRequest
            }
            else if (typeof refresh !== 'undefined' && urlToCheck !== '/logout') {
                //console.log('Area privada: ' + urlToCheck + ' isExpireTime:' + isExpireTime + ' token:' + token + " - hi ha refesh token per tan podem demanar un nou token vinculat a un usuari")
                securityHelper.managerRefreshToken() //la funcion només demanarà un sol refesh token
                const retryOriginalRequest = new Promise((resolve) => {
                    //console.log('crea la promesa que posarem un accessToken')
                    securityHelper.addSubscriber(access_token => {
                        originalRequest.headers.Authorization = 'Bearer ' + access_token.data.access_token
                        resolve(originalRequest)
                    })
                }) // a través de la promesa
                //console.log('devolució de la promesa')
                return retryOriginalRequest
            }else if(urlToCheck !== '/logout'){
                // No té token ni refresh token
                let currentRoute = router.history.current.name;
                //console.log("router: ",currentRoute);
                if(currentRoute!="Login") {
                    router.push('/login').catch(e => {
                        console.log("Error route: '/login'", e)
                    });
                }
            }else if(urlToCheck === '/logout' && typeof token !== 'undefined'){
                //console.log("fem logout - token anònim? token:" + token + ' anonymous:' + anonymous + ' urlToCheck:' + urlToCheck);
                return config;
            }
        } else if (typeof token !== 'undefined' && anonymous && !anonymousUrls.includes(urlToCheck)) { // Estic fent servir un token anonim i l'acció no és anonima, m'ha de fer fora
            //console.log('token anònim amb un url no anònima. token:' + token + ' anonymous:' + anonymous + ' urlToCheck:' + urlToCheck)
            router.push('/logout').catch(e => { console.log("Error route axiosFilter: '/logout'", e) });
        } else { // Tinc token i accès
            //console.log('tenim token i acccés')
            config.headers['Authorization'] = 'Bearer ' + sessionStorage.token;
            return config
        }
    },
    onFulfilledResponse (response) {
        //console.log('onFulfilledResponse')
        store.commit('clearError')
        return response
    },
}

