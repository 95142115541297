import entityHelper from '@/api/entityHelper';
import axios from 'axios';
import image from '../../utils/image';

export default {
    clientImage(clientCode) {
        return axios.get('/clientImage', {responseType: 'arraybuffer', params: {clientCode: clientCode}}).then(
            (response) => {
                if (response.data !== "" && response.data !== "undefined" && response.data.byteLength > 0) {
                    let bytes = new Uint8Array(response.data); // get info de la imatge que hem serveix el backend
                    let binary = bytes.reduce((data, b) => data += String.fromCharCode(b), ''); // bytes latin
                    return "data:image/jpeg;base64," + btoa(binary); // base62
                }
            }
        );
    },
    uploadLogo(clientCode, dataUrl) {
        let file = null;
        let config = {};
        let data = new FormData();

        // Convert dataUrl to File.
        file = (dataUrl) ? image.dataUrlToFile(dataUrl, 'logo.png') : null;

        config = {
            headers : { 'Content-Type': 'multipart/form-data' }
        };

        data.append('client_code', clientCode);
        data.append('logo_image', file);
        // console.log(data, config);
        return axios.post('/uploadClientLogo', data, config).then((response) => {
            if (response) {
                return response.data;
            }
        });
    },
}
