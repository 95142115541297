import subjectApi from '@/api/core/subject'
import Vue from 'vue';
import i18n from '@/translations';
export const subjects = {
    state: {
        subjects: null,
        subjectTypes: {},
        subjectsByCourseStage: {},
        subjectsById: {},
        subjectsForceUpdate: false,
    },
    mutations: {
        setSubjects(state, subjects){
            state.subjects = subjects;
        },
        setSubjectTypes(state, params){
            state.subjectTypes[params.stageId] = params.subjectTypes;
        },
        setSubjectByCourseStage(state, params){
            if(state.subjectsByCourseStage[params.year]){
                state.subjectsByCourseStage[params.year] = {}
            }
            state.subjectsByCourseStage[params.year][params.courseStageId] = params.subjects;
        },
        setSubjectById(state, params){
            state.subjectsById[params.id] = params.subject;
        },
        setSubjectsForceUpdate(state, value){
            state.subjectsForceUpdate = value;
        }
    },
    actions: {
        async getSubject(context, params){
            let force = false;
            if(typeof params != "undefined" && 'force' in params){   force = params.force;  }
            if(!force && context.state.subjectsById[params.subjectId] && context.state.subjectsById[params.subjectId] !== "pending"){
                return context.state.subjectsById[params.subjectId];
            } else if(context.state.subjectsById[params.subjectId] !== "pending" && params.subjectId){
                context.commit("setSubjectById", {id: params.subjectId, subject: "pending"});
                await subjectApi.getSubject(params.subjectId).then((response) => {
                    if (response) {
                        context.commit("setSubjectById", {id: params.subjectId, subject: response.data});
                    }
                    return response.data;
                }).catch(e => {
                    context.commit("setSubjectById", {subjectId: params.subjectId, subject: null});
                });
            }
        },
        async getSubjectTypes(context, params){
            if (context.state.subjectTypes[params.stageId]){
                return context.state.subjectTypes[params.stageId];
            }
            else if (context.state.subjectTypes[params.stageId] !== "pending"){
                Vue.set(context.state.subjectTypes, params.stageId, "pending");
                let self = this;
                await subjectApi.getSubjectTypesByStage(params.stageId).then(response => {
                    let subjectsTypeValues = [];
                    if(response){
                        response.data.forEach(function (element) {
                            subjectsTypeValues.push({
                                label: element.keyName ? i18n.tc(element.keyName) : element.name,
                                name: element.keyName ? i18n.tc(element.keyName) : element.name,
                                value: element.id,
                                id: element.id,
                                isDefault: element.isDefault,
                                keyName: element.keyName});
                        });
                    }
                    Vue.set(context.state.subjectTypes, params.stageId, subjectsTypeValues);
                });
            }
        },
        async getSubjectsByCourseStage(context, params){
            if (context.state.subjectsByCourseStage[params.year] && context.state.subjectsByCourseStage[params.year][params.courseStageId] && !context.state.subjectsForceUpdate){
                return context.state.subjectsByCourseStage[params.year][params.courseStageId];
            }
            else if (!context.state.subjectsByCourseStage[params.year] || 
                context.state.subjectsByCourseStage[params.year] && context.state.subjectsByCourseStage[params.year][params.courseStageId] !== "pending"){
                if(!context.state.subjectsByCourseStage[params.year]){
                    Vue.set(context.state.subjectsByCourseStage, params.year, {});
                }
                Vue.set(context.state.subjectsByCourseStage[params.year], params.courseStageId, "pending");
                await subjectApi.getSubjectForCourseStage(params.courseStageId, params.year).then(response => {
                    let subjectsValues = [];
                    if(response){
                        response.data.forEach(function (element) {
                            subjectsValues.push({ label: element.name, value: element.id, name: element.name });

                        });
                    }
                    Vue.set(context.state.subjectsByCourseStage[params.year], params.courseStageId, subjectsValues);
                });
            }
        }
    },
    getters: {
        getSubjects: state =>{
            return state.subjects;
        },
        getSubjectsById: state =>{
            return state.subjects;
        },
        getSubjectTypes: state =>{
            return state.subjectTypes;
        },
        getSubjectsForceUpdate: state =>{
            return state.subjectsForceUpdate;
        }
    }
}
