export default {
    "studentCommentSaved": "El comentario del alumno se ha guardado correctamente",
    "confirmDeleteComment": "¿Seguro que desea eliminar el comentario '{commentText}'? ",
    "studentCommentDeleted": "El comentario del alumno se ha eliminado correctamente",
    "homework": "Deberes",
    "classTime": "Clases (horario)",
    "classNoTime": "Clases",
    "plannedAt": "Fecha planificación",
    "hours": "Horas",
    "plannedHours": "Horas planificadas",
    "executedAt": "Fecha Ejecución",
    "executedHours": "Horas ejecutadas",
    "noClassContents": "Esta semana no tiene ninguna sesión, revisa la semana siguiente o la anterior.",
    "contentText": "Texto",
    "evaluation": "Evaluación",
    "planned": "Planificado",
    "notPlanned": "No Planificado",
    "executed": "Realizado",
    "notExecuted": "No realizado",
    "done": "Hecho",
    "addClassContent": "Añadir nuevo contenido",
    "newClassContent": "Nueva entrada - {elementType}",
    "editClassContent": "Editar entrada - {elementType}",
    "showClassContent": "Ver entrada - {elementType}",
    "confirmDeleteEvent": "¿Seguro que quiere eliminar el evento seleccionado?",
    "confirmDeleteClassContent": "¿Quiere eliminar el contenido seleccionado?",
    "evaluationCriteria": "Criterio de evaluación|Criterios de evaluación",
    "textComment": "Texto del comentario",
    "lessons": "Temarios",
    "sessionNoContent": "No se ha definido lo que impartirás en esta sesión",
    "sessionNoContentTEMARY": "No se han definido los contenidos que impartirás en esta clase",
    "sessionNoContentOBJECTIVES": "No se han definido los objetivos que trabajarás en esta clase",
    "sessionNoContentACTIVITY": "No se han definido las actividades que haréis en esta clase",
    "sessionNoContentDOCUMENTS": "No hay archivos adjuntos en esta clase",
    "sessionNoContentCOMPETENCES": "¿Qué competencias trabajaréis en esta clase?",
    "sessionNoContentDESCRIPTION": "No hay descripción para esta sesión",
    "selectAddContent": "Añade lo que impartirás en esta sesión",
    "selectAddContentTEMARY": "Temario de '{classe}'",
    "selectAddContentOBJECTIVES": "Objetivos de '{classe}'",
    "selectAddContentACTIVITY": "Actividades de '{classe}'",
    "selectAddContentDOCUMENTS": "Archivos de '{classe}'",
    "selectAddContentCOMPETENCES": "Competencias de '{classe}'",
    "selectAddContentDESCRIPTION": "Descripciones de '{classe}'",
    "selectAddLessons": "Selecciona o añade el temario que impartirás en esta sesión",
    "selectAddLessonsTEMARY": "Selecciona o añade el temario que impartirás en esta sesión",
    "selectAddLessonsOBJECTIVES": "Selecciona o añade los objetivos que trabajarás en esta sesión",
    "selectAddLessonsACTIVITY": "Selecciona o añade las actividades que haréis en esta sesión",
    "selectAddLessonsCOMPETENCES": "Selecciona o añade las competencias que trabajaréis en esta sesión",
    "selectAddLessonsDESCRIPTION": "Selecciona o añade las descripción que trabajaréis en esta sesión",
    "objectives": "Objetivos",
    "activities": "Actividades",
    "newTask": "Nueva tarea",
    "tasksDone": "Tareas realizadas",
    "progress": "Progresión",
    "addLesson": "Nuevo temario",
    "addTask": "Añadir tarea",
    "noContentAvailable": "No hay contenido disponible.",
    "sessions": "Sesiones",
    "sessionsTEMARY": "Temario / Contenidos",
    "sessionsOBJECTIVES": "objetivos",
    "sessionsACTIVITY": "Actividades",
    "sessionsDESCRIPTION": "Descripción",
    "noSubProgramContent": "No hay sub-temas definidos",
    "back_to_class": "Volver al seguimiento",
    "classContentDeleted": "El contenido de clase se ha eliminado correctamente.",
    "confirmDeleteClasse": "¿Seguro que quieres borrar la clase de este día? ¡Si hay contenidos, muévelos antes!",
    "selectClass": "Selecciona una clase",
    "addSession": "Añadir una nueva sesión",
    "TEMARY": "Temario",
    "OBJECTIVES": "Objetivos",
    "ACTIVITY": "Actividades",
    "DOCUMENTS": "Documentos",
    "COMPETENCES": "Competencias",
    "DESCRIPTION": "Descripciones",
    "contentType": {
        "TEMARY": "Temario|Temarios",
        "OBJECTIVES": "Objetivo|Objetivos",
        "ACTIVITY": "Actividad|Actividades",
        "DOCUMENTS": "Documento|Documentos",
        "DOCUMENT": "Documento|Documentos",
        "COMPETENCES": "Competencia|Competencias",
        "COMPETENCE": "Competencia|Competencias",
        "DESCRIPTION": "Descripción|Descripciones",
        "COMMENT": "Comentario|Comentarios",
        "REFLECTION": "Reflexión|Reflexiones",
    }
}
