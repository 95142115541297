
import calendarApi from '@/api/core/calendar'

export const calendar = {
    state: {
        availableCalendars: null,
        availableCalendarsParam: null,
    },
    mutations: {
        setAvailableCalendars(state, availableCalendars){
            state.availableCalendars = availableCalendars;
        },
        setAvailableCalendarsParam(state, availableCalendarsParam){
            state.availableCalendarsParam = availableCalendarsParam;
        }
    },
    actions: {
        async availableCalendars({state,commit}, params){
            let force = false;
            if(params && Object.keys(params).length > 0 && 'force' in params){
                force = params.force;
            }

            if (!force && state.availableCalendars != null && state.availableCalendars != 'pending'){
                return state.availableCalendars
            } else if((!force && state.availableCalendars != 'pending') || force) {
                commit('setAvailableCalendars', 'pending');
                await calendarApi.getAvailableCalendars(params?.onlyIds ?? null).then(response => {
                    //console.log("getAvailableCalendars store", response.data.calendars);
                    commit('setAvailableCalendars', response.data.calendars);
                    commit('setAvailableCalendarsParam', response.data.parameters);
                });
            }
        }
    },
    getters: {
        getAvailableCalendars: state => {
            return state.availableCalendars;
        },
        getAvailableCalendarsParam: state => {
            return state.availableCalendarsParam;
        },
        getAvailableCalendarsParamIndexed: state => {
            let parameters = {};
            if(state.availableCalendarsParam!=null && Array.isArray(state.availableCalendarsParam)) {
                state.availableCalendarsParam.forEach(p => {
                    parameters[p.keyName] = p.value;
                });
            }
            return parameters;
        }
    }
};
