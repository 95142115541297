import axios from 'axios';
import apiOauth from '@/api/core/auth';

export const security = {
    state: {
        passwordChangedResponse: null,
        recoverPasswordResponse: null,
    },
    mutations: {
        setPasswordChangedResponse(state, value){
            state.passwordChangedResponse = value;
        },
        setRecoverPasswordResponse(state, value){
            state.recoverPasswordResponse = value;
        },
    },
    actions: {
        recoverPassword({commit, state}, params) {
            // axios.headers.post['Content-Type'] = 'form-data';
            const parameters = new URLSearchParams();
            parameters.append('email', params.email);
            try{
                return axios.post('/recoverPassword', parameters).then(response => {
                    // console.log("setRecoverPasswordResponse", response);
                    commit("setRecoverPasswordResponse", response);
                }).catch(e => {
                    commit("setRecoverPasswordResponse", e.response);
                });
            }catch(exception){
                // console.log("setRecoverPasswordResponse", exception);
                commit("setRecoverPasswordResponse", exception);
            }

        },
        async changePassword({commit, state}, params) {
            if (localStorage.anonymous == true || localStorage.anonymous == undefined) {
                let anonimus = await apiOauth.requestAnonimusToken();
                sessionStorage.token = anonimus.data.access_token;
                localStorage.anonymous = true;
            }
            const parameters = new URLSearchParams();
            parameters.append('password', params.password);
            if (params.userId != undefined) {
                parameters.append('userId', params.userId);
            }
            if (params.tempId != undefined) {
                parameters.append('tempId', params.tempId);
            }
            if(!params.token){
                parameters.append('token', sessionStorage.token);
            }
            else{
                parameters.append('token', params.token);
            }
            if (params.yearCode != undefined) {
                parameters.append('yearCode', params.yearCode);
            }
            if (params.centerCode != undefined) {
                parameters.append('centerCode', params.centerCode);
            }
            await axios.post('/changePassword', parameters, {cancelToken: axios.CancelToken.source().token}).then(response => {
                commit("setPasswordChangedResponse", response);
            });
        },
    },
    getters: {
        getPasswordChangedResponse: state => {
            return state.passwordChangedResponse
        },
        getRecoverPasswordResponse: state => {
            return state.recoverPasswordResponse
        },
    }
}

