import gradesApi from '@/api/grades/grades';
import Vue from 'vue';

export const grades = {
    state: {
        evaluabletypes: null,
        evaluationPeriods: {},
        evaluationPeriodsDesc: {},
        periods:{},
        convocatories: {},
        classesToolsClicked: false,
        ambitsList: null,
        ambitOfProgram: {},
        convocatoriesList: {},
        predefinedComments: [],
        commentsCategories: [],
        showEditEvaluable: {show: false, evaluable: null},
        showEditCompetence: {show: false, convocatory: null},
        showGradesColumns: {},
        defaultEvaluableType: null,
        allConvocatories: null,
        popoverGlobalData: { showPC: false, position: null },
        dragStartConvocatoriesList: null,
        onDropConvocatoriesList: null
    },
    mutations: {
        setEvaluabletypes(state, evaluabletypes){
            state.evaluabletypes = evaluabletypes;
        },
        setEvaluationPeriods(state, params){
            if(!state.evaluationPeriods[params.yearCode]){
                Vue.set(state.evaluationPeriods, params.yearCode, {} );
            }
            Vue.set(state.evaluationPeriods[params.yearCode], params.courseStageId, params.evaluationPeriods);
        },
        setEvaluationPeriodsDesc(state, params){
            if(!state.evaluationPeriodsDesc[params.yearCode]){
                Vue.set(state.evaluationPeriodsDesc, params.yearCode, {} );
            }
            Vue.set(state.evaluationPeriodsDesc[params.yearCode], params.courseStageId, params.evaluationPeriodsDesc);
        },
        setConvocatories(state, params){
            if(!state.convocatories[params.yearCode]){
                Vue.set(state.convocatories, params.yearCode, {} );
            }
            Vue.set(state.convocatories[params.yearCode], params.courseStageId, params.convocatories);
        },
        setClassesToolsClicked(state, type){
            state.classesToolsClicked = type;
        },
        setAmbitsList(state, type){
            state.ambitsList = type;
        },
        setAmbitOfProgram(state, params){
            if(!state.ambitOfProgram[params.yearCode]){
                Vue.set(state.ambitOfProgram, params.yearCode, {} );
            }
            Vue.set(state.ambitOfProgram[params.yearCode], params.programId, params.ambit);
        },
        resetEvaluationPeriods(state){
            state.evaluationPeriods = {};
        },
        setCompetences(state, type){
            state.competences = type;
        },
        setPeriods(state, params){
            Vue.set(state.periods, params.courseStageId, params.periods);
        },
        setConvocatoriesList(state, convoList){
            state.convocatoriesList = convoList;
        },
        setPredefinedComments(state, predefinedComments){
            state.predefinedComments = predefinedComments;
        },
        setCommentsCategories(state, commentsCategories){
            state.commentsCategories = commentsCategories;
        },
        setShowEditEvaluable(state, type){
            state.showEditEvaluable = type;
        },
        setShowEditCompetence(state, type){
            state.showEditCompetence = type;
        },
        setShowGradesColumns(state, type){
            state.showGradesColumns = type;
        },
        setDefaultEvaluableType(state, type){
            state.defaultEvaluableType = type;
        },
        setPopoverGlobalData(state, params){
            sessionStorage.popoverGradesGlobalData = JSON.stringify(params);
            state.popoverGlobalData = params;
        },
        setAllConvocatories(state, type){
            state.allConvocatories = type;
        },
        setDragStartConvocatoriesList(state, type){
            state.dragStartConvocatoriesList = type;
        },
        setOnDropConvocatoriesList(state, type){
            state.onDropConvocatoriesList = type;
        }
    },
    actions: {
        async getEvaluationPeriods(context, params){
            if (params.yearCode && params.courseStageId && context.state.evaluationPeriods[params.yearCode] && context.state.evaluationPeriods[params.yearCode][params.courseStageId] && !params.force){
                return context.state.evaluationPeriods[params.yearCode][params.courseStageId];
            }
            else if (!context.state.evaluationPeriods[params.yearCode] || !context.state.evaluationPeriods[params.yearCode][params.courseStageId] || context.state.evaluationPeriods[params.yearCode] && context.state.evaluationPeriods[params.yearCode][params.courseStageId] !== "pending" || params.force){
                context.commit('setEvaluationPeriods', {yearCode: params.yearCode, courseStageId: params.courseStageId, evaluationPeriods: "pending" });
                await gradesApi.getEvaluationPeriodsByCourseStage(params.courseStageId, params.cancelToken, params.yearCode).then(response =>{
                    let info = response.data.data;
                    context.commit('setEvaluationPeriods', {yearCode: params.yearCode, courseStageId: params.courseStageId, evaluationPeriods: info.resultAsc} );
                    context.commit('setEvaluationPeriodsDesc', {yearCode: params.yearCode, courseStageId: params.courseStageId, evaluationPeriodsDesc: info.resultDesc} );
                    // let periods =  info.resultAsc.filter(p => {   if (!p.isFinal){  return p;  }  });
                    let periods =  info.resultAsc;
                    context.commit('setPeriods', {yearCode: params.yearCode, courseStageId: params.courseStageId, periods: periods} );
                    // let convocatories =  info.resultAsc.filter(c => {   if (c.isFinal){  return c;  }  });
                    let convocatories =  info.resultAsc;
                    context.commit('setConvocatories', {yearCode: params.yearCode, courseStageId: params.courseStageId, convocatories: convocatories} );
                });
            }
        },
        async savePersonEvaluableGradeValue(context, params){
            await gradesApi.savePersonEvaluableGradeValue(
                params.gradeEvaluableId, params.studentId, params.evaluableId, params.evaluationPeriodId, params.value, params.type, params.isFinal).then(response => {
                let grade = {id: response.data.data.id, value: params.value};
                return grade;
            });
        },
        async getPeriods(context, params){
            context.dispatch('getEvaluationPeriods', params);
        },
        async getConvocatories(context, params) {
            context.dispatch('getEvaluationPeriods', params);
        },
        async getConvocatoriesList(context,params){
            let force = false;
            if(typeof params != "undefined" && 'force' in params){   force = params.force;  }
            if(!force && context.state.convocatoriesList!=="pending" && context.state.convocatoriesList && context.state.convocatoriesList.length > 0){
                return context.state.convocatoriesList;
            } else if(context.state.convocatoriesList !== "pending" || force){
                context.commit("setConvocatoriesList", "pending");
                let filters = [];
                if(typeof params != "undefined" && 'filters' in params){ filters = params.filters; }
                await gradesApi.findEvaluationPeriods(filters, 1, 500, "evaluationPeriodCourse", "asc").then(response => {
                    if (response && Array.isArray(response.data.data.data)) {
                        context.commit("setConvocatoriesList", response.data.data.data);
                    } else {
                        context.commit("setConvocatoriesList", []);
                    }
                    return context.state.convocatoriesList;
                }).catch(e => {
                    context.commit("setConvocatoriesList", []);
                });
            }
        },
        async getAmbitsList(context, params){
            if(context.state.ambitsList!== "pending") {
                context.commit("setAmbitsList", "pending");
                await gradesApi.getAmbitsDropdown().then((response) => {
                    if(response){
                        let ambits = response.data.data;
                        context.commit("setAmbitsList", ambits);
                    }
                });
            }else {
                return context.state.ambitsList;
            }
        },
        async getEvaluabletypes(context, params){
            if((!context.state.evaluabletypes || context.state.evaluabletypes.length === 0) && context.state.evaluabletypes!== "pending") {
                context.commit("setEvaluabletypes", "pending");
                await gradesApi.getEvaluabletypes().then((response) => {
                    let evaluabletypes = response.data.data;
                    context.commit("setEvaluabletypes", evaluabletypes);
                });
            }else {
                return context.state.evaluabletypes;
            }
        },
        async getAmbitOfProgram(context, params){
            if (context.state.ambitOfProgram[params.yearCode] && context.state.ambitOfProgram[params.yearCode][params.programId]){
                return context.state.ambitOfProgram[params.yearCode][params.courseStageId];
            }
            else if (!context.state.ambitOfProgram[params.yearCode] || context.state.ambitOfProgram[params.yearCode][params.programId] !== "pending"){
                context.commit('setAmbitOfProgram', {yearCode: params.yearCode, programId: params.programId, ambit: "pending" });
                await gradesApi.getAmbitOfProgram(params.programId, params.yearCode).then(response =>{
                    context.commit('setAmbitOfProgram', {yearCode: params.yearCode, programId: params.programId, ambit: response.data.data} );
                });
            }
        },
        async getMyCompetences(context){
            if(!context.state.competences || context.state.competences.length === 0) {
                await gradesApi.getCompetences().then((response) => {
                    let competences = response.data.data;
                    context.commit("setCompetences", competences);
                });
            }else {
                return context.state.competences;
            }
        },
        async getPredefinedComments(context, params){
            let force = false;
            if(typeof params != "undefined" && 'force' in params){ force = params.force; }
            if(!force && context.state.predefinedComments !== "pending" && context.state.predefinedComments && context.state.predefinedComments.length > 0){
                return context.state.predefinedComments;
            } else if (context.state.predefinedComments !== "pending") {
                context.commit("setPredefinedComments", "pending");
                let filters = [];
                if(typeof params != "undefined" && 'filters' in params){ filters = params.filters; }
                gradesApi.getPredefinedComments(filters, 1, 500, "value", "asc").then(response => {
                    if (response && Array.isArray(response.data.commentGradesDefault)) {
                        context.commit("setPredefinedComments", response.data.commentGradesDefault);
                    } else {
                        context.commit("setPredefinedComments", []);
                    }
                    return context.state.convocatoriesList;
                }).catch(e => {
                    context.commit("setPredefinedComments", []);
                });
            }
        },
        async getCommentsCategories(context, params){
            let force = false;
            if(typeof params != "undefined" && 'force' in params){   force = params.force;  }
            if(!force && context.state.commentsCategories !== "pending" && context.state.commentsCategories && context.state.commentsCategories.length > 0){
                return context.state.commentsCategories;
            } else if (context.state.commentsCategories !== "pending") {
                context.commit("setCommentsCategories", "pending");
                let filters = [];
                if(typeof params != "undefined" && 'filters' in params){ filters = params.filters; }
                // gradesApi.getCommentsCategories(filters, 1, 500, "value", "asc").then(response => {
                gradesApi.getPredefinedCommentCategories().then(response => {
                    if (response && Array.isArray(response.data.commentGradesCategories)) {
                        context.commit("setCommentsCategories", response.data.commentGradesCategories);
                    } else {
                        context.commit("setCommentsCategories", []);
                    }
                    return context.state.commentsCategories;
                }).catch(e => {
                    context.commit("setCommentsCategories", []);
                });
            }
        },
        async getDefaultEvaluableType(context, params){
            if(context.state.defaultEvaluableType!== "pending") {
                context.commit("setDefaultEvaluableType", "pending");
                await gradesApi.getDefaultEvaluableType().then(response => { //formativeCycle
                    let data = response.data.data;
                    context.commit("setDefaultEvaluableType", data);
                });
            }else {
                return context.state.defaultEvaluableType;
            }
        },
        async getAllConvocatories(context, params){
            if((context.state.allConvocatories !== "pending" && !Array.isArray(context.state.allConvocatories)) || (params && Object.keys(params).length > 0 && Object.keys(params).includes('force') && params.force)) {
                context.commit("setAllConvocatories", "pending");
                await gradesApi.getAllConvocatories(params?.filters).then(response => {
                    if(response){
                        let data = response.data.data.data;
                        function flattenObjects(data) {
                            let flattenedArray = [];
                            function flatten(obj, level = 0) {
                                obj.name = '    '.repeat(level) + obj.name;
                                Vue.set(obj, 'labelName', '    '.repeat(level) + '└ ' + obj.code);
                                flattenedArray.push(obj);
                                if (obj.children && obj.children.length > 0) {
                                    obj.children.forEach(child => {
                                        flatten(child, level + 1);
                                    });
                                }
                            }
                            data.forEach(parent => {
                                flatten(parent);
                            });
                            return flattenedArray;
                        }
                        let flattenedData = flattenObjects(data);
                        context.commit("setAllConvocatories", flattenedData);
                    } else {
                        context.commit("setAllConvocatories", "error");
                    }
                }).catch(e => {
                    context.commit("setAllConvocatories", "error");
                });
            }else {
                return context.state.allConvocatories;
            }
        }
    },
    getters: {
        getEvaluationPeriods: state => {
            return state.evaluationPeriods;
        },
        getEvaluationPeriodsDesc: state => {
            return state.evaluationPeriodsDesc;
        },
        getPeriods: state => {
            return state.periods
        },
        getConvocatories: state => {
            return state.convocatories;
        },
        getConvocatoriesList: state => {
            return state.convocatoriesList;
        },
        getPrintGradesClicked: state => {
            return state.printGradesClicked;
        },
        getAmbitsList: state => {
            return state.ambitsList;
        },
        getEvaluabletypes: state => {
            return state.evaluabletypes;
        },
        getMyCompetences: state => {
            return state.competences;
        },
        getPredefinedComments: state => {
            return state.predefinedComments;
        },
        getCommentsCategories: state => {
            return state.commentsCategories;
        },
        getShowEditEvaluable: state => {
            return state.showEditEvaluable;
        },
        getShowEditCompetence: state => {
            return state.showEditCompetence;
        },
        getShowGradesColumns: state => {
            return state.showGradesColumns;
        },
        getDefaultEvaluableType: state => {
            return state.defaultEvaluableType;
        },
        getPopoverGlobalData: state => {
            if(sessionStorage.getItem('popoverGradesGlobalData') == null){
                return state.popoverGlobalData;
            }
            return JSON.parse(sessionStorage.getItem('popoverGradesGlobalData'));
        },
        getAllConvocatories: state => {
            return state.allConvocatories;
        },
        getDragStartConvocatoriesList: state => {
            return state.dragStartConvocatoriesList;
        }
    }
};
