<template>
  <transition
    name="modal"
    @after-enter="$emit('open')"
    @after-leave="$emit('close')">
    <div :id="identifier" class="ieduca-dialog modal-mask" :class="visibility">
      <div class="modal-wrapper">
        <div class="modal-container shadow">
            <div class="row">
              <div class="col-sm-12 content-modal">
                <div name="content">
                  <h3>{{$t("header.privacity")}}</h3>
                  <div v-resizeable="30" >
                  <p v-html="parsedBody"></p>
                  </div>
                </div>
              </div>
            </div>
          <div class="wrapper-icon btn-modal-close" href="#" @click.prevent="close()">
            <a href="#" @click.prevent="close()"><i class="icon icon-close-alt2"></i></a>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
    import $ from 'jquery';
    import {Resizeable} from "@/directives/Resizeable";

    export default {
        name: "modal-privacity",
        directives: {Resizeable},
        props: {
            id: {type: String, default: ""},
            decoration: {type: String, default: "default"},
            width: {default: 50},
            timeToLive: {type: Number, default: 0},
            visible: {type: [Number, Boolean], default: false},
        },
        data() {
            return {
            }
        },
        methods: {
            show() {
                if ($('#' + this.identifier + '.ieduca-dialog').hasClass('d-none')) {
                    $('#' + this.identifier + '.ieduca-dialog').removeClass('d-none');
                }
            },
            destroy() {
                $('#' + this.identifier + '.ieduca-dialog').remove();
            },
            open() {
                // Alias de show.
                this.show();
            },
            close() {
                if (!$('#' + this.identifier + '.ieduca-dialog').hasClass('d-none')) {
                    $('#' + this.identifier + '.ieduca-dialog').addClass('d-none');
                }
                this.$emit('close')
            },
            setSize() {
                $('#' + this.identifier + ' .modal-container').css("width", this.width + "%");
                //$(".modal-container").css("height", this.height+"px");
            }
        },
        computed: {
            visibility() {
                let isVisible = "d-none";

                if (this.lodash.isFinite(this.visible) && this.visible > 0) {
                    setTimeout(() => {
                        $('#' + this.identifier + '.ieduca-dialog').addClass('d-none');
                    }, this.visible * 1000);

                    isVisible = "";
                } else if (this.visible === true) {
                    isVisible = "";
                }

                return isVisible;
            },
            parsedBody() {
                return this.$i18n.t('privacity');
            },
            error: {
                get() {
                    return this.$store.state.error;
                }
            },
            withIcon() {
                if (!this.lodash.isEmpty(this.modalIcon)) {
                    return "with-icon";
                }

                return "";
            },
            blur() {
                if (this.loading) {
                    return 'bg-blur';
                }
                return '';
            },
            colContent() {
                if (this.modalIcon == undefined || this.modalIcon == '') {
                    return '';
                }
                return ' col-9';
            },
            identifier() {
                if (this.id != "") {
                    return this.id;
                } else {
                    return this.lodash.uniqueId('ieduca_modal_');
                }
            }
        },
        mounted() {
            this.setSize();
        }
    }
</script>

<style scoped lang="scss">
    @import "../../assets/css/variables.scss";
    @import "../../assets/css/ieduca_font.css";
  .content-modal {
    color: $black;
  }

  h3 {
    margin: 2rem 0rem 1rem 0rem;
  }

  .big-icon {
    font-size: 4rem;
    padding: 1rem 0rem 1rem 2rem;
  }

  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .3);
    display: table;
    transition: opacity .3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  @media (max-width: 480px) {
    .modal-container {
      min-width: 100% !important;
    }
  }

  .modal-container {
    margin: 0px auto;
    background-color: #fff;
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
    position: relative;
    min-height: 120px;
    max-height: 800px;
    min-width: 480px;
    border-top: 9px;
    overflow: auto;
    .btn-modal-close {
      display: block;
      margin: 0;
      padding: 0;
      position: absolute;
      right: 20px;
      top: 8px;

      color: #6f768b;
      z-index: 999;
    }

    .btn-modal-back {
      display: block;
      margin: 0;
      padding: 0;
      position: absolute;
      left: 20px;
      top: 8px;
      font-size: 0.75rem;
      /*width: 10px;
      height: 10px;*/
      color: #6f768b;
      z-index: 999;
    }
    &.with-icon {
      .modal-header, .modal-body {
        padding-left: 120px !important;
      }
    }
  
    &.small {
      width: 375px !important;
    }
  
    &.medium {
      width: 564px !important;
    }
  
    &.large {
      width: 800px !important;
    }
  
    &.success {
      border-top: 9px solid $success !important;
      .big-icon { color: $success !important; }
    }
  
    &.error {
      border-top: 4px solid $error !important;
      .big-icon { color: $error !important; }
    }
  
    &.warning {
      border-top: 9px solid $warning !important;
      .big-icon {  color: $warning !important; }
    }
  
    &.info {
      border-top: 9px solid $info !important;
      .big-icon { color: $info !important; }
    }
  
    &.notification {
      .modal-header {
        padding: 44px 48px 12px 48px;
        h3 { font-weight: 700; }
      }
    }
  }

  .modal-header {
    border: none;
    padding: 48px 48px 47px 48px;

    h3 {
      margin: 0;
      padding: 0;
      text-align: left;
      width: 100%;
      font-size: 20px;
    }
  }

  .modal-body {
    padding: 0 48px 48px 48px;
    color: $semiNegre;
  }

  .modal-default-button {
    float: right;
  }

  /* TRANSITION */
  .modal-enter, .modal-leave-active {
    opacity: 0;
    .modal-container {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }

  .bg-blur {
    -webkit-filter: opacity(15%);
    filter: opacity(15%);
  }
</style>
