import axios from 'axios';

export default {

  extract(entity, file, context) {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("entity", entity);

    return axios.post('/import/extract', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: function( progressEvent ) {
        let percentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
        context.commit("setPercentage", percentage);

      }.bind(this)
    }).then(response => {

      if (typeof response.data.error != 'undefined') {
        return Promise.reject(response.data.error);
      } else {
        return response.data.records;
      }
    }).catch(error => {
      return Promise.reject(error)
    });

  },

  findActiveSession(entity){

    // Preguntem si hi ha una sessio activa per aquest entity
    return axios.get('/import/findsession?entity=' + entity);


  },
  updateSession(entity, pendingRecords, headingOnFirstRow, fieldMapping){
    axios.post('/import/updatesession?entity=' + entity, {
      records: pendingRecords,
      headingOnFirstRow: headingOnFirstRow,
      mapping: fieldMapping
    })
  },
  getSampleFiles(entity){
    return axios.get('/import/samples?entity=' + entity);
  },
  downloadFile(entity, filename){
    //console.log("downloading " + filename + " entity " + entity);
    return axios.get('/import/getsample?entity=' + entity + "&filename=" + filename, {responseType: 'blob'})
  },
  importRecord(entity, records, mapping, simulation, options = null){
    //console.log("records", records);
    if(simulation) {
      return axios.post('/import/validate', {entity, records, mapping});
    }else{
      return axios.post('/import/import', {entity, records, mapping, options});
    }
  },
  exportErrors(entity, records, mapping){

    return axios.post('/export', {entity, records, mapping}, {responseType: 'blob'});
  }
}
